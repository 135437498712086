import { User } from '../../../_models/user';


export class PartyDetails{
    id:number=0;
    party_id:number=0;
    first_name:string="";
    middle_name:string="";
    last_name:string="";
    identity_proof_image1:string|any;
    identity_proof_image2:string|any;
    identity_proof_image3:string|any;
    other_document:string|any;
    gst:string="";
    pan:string="";
    adhar:string="";
    police_station_address:string="";
    updated_by: User = new User();
    deleted_at:string;
    created_at:string;
    updated_at:string;
}
