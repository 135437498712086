import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from '@app/_services/employee.service';
import { Employee } from '@app/_models/payroll';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  @Input('employee') employee :  Employee = new Employee();
  @Output() public submitSuccess: EventEmitter<Employee> = new EventEmitter<Employee>();
  @Output() cancel = new EventEmitter();
  submitted: boolean = false;
  loading:boolean = false;
  uploadImageForm : UntypedFormGroup;
  error = '';
  uploaded_image_url = '';
  @ViewChild('imageCropper', { static: true }) imageCropper: ElementRef;
  showCropper = false;
  showActionButton = false;
  constructor(private employeeService: EmployeeService, private formBuilder: UntypedFormBuilder) { }

  ngOnChange(changes: Employee)
  {
    this.uploaded_image_url =  this.employee.profile_image;
  }

  ngOnInit() {
    this.uploaded_image_url =  this.employee.profile_image;
    //this.initForm();
  }
   /*get f() { return this.uploadImageForm.controls; }
  initForm(){
    this.uploadImageForm = this.formBuilder.group({
      uploaded_image : ['', [Validators.required]]
    });
  }*/
  onSubmit() {
    if (this.employee.id > 0) {
      this.submitted = true;
      /*if (this.uploadImageForm.invalid) {
        this.loading = false;
        return;
      }*/
      this.loading = true;
      //let _employee: Employee = new Employee();
      //_employee.id = this.employee.id;
      //_employee.profile_image = this.f.uploaded_image.value;
      this.employee.profile_image = { value: this.croppedImage };
      this.employeeService.updateAttribute(this.employee.id, 'profile_image', this.employee).subscribe(x => {
        this.submitted = false;
        this.loading = false;
        this.showCropper = false;
        this.showActionButton = false;
        this.submitSuccess.emit(x);
      },
        error => {
          this.error = error;
          this.submitted = false;
          this.loading = false;
        })
    } else {
      //this.croppedImage = "";
      this.showCropper = false;
      this.showActionButton = false;
      this.employee.profile_image = { value: this.croppedImage };
      this.submitSuccess.emit(this.employee);
    }
  }
  onCancel() {
    this.croppedImage = "";
    this.showCropper = false;
    this.showActionButton = false;
  }
  // Image Preview
  /*showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadImageForm.patchValue({
      uploaded_image: file
    });
    this.uploadImageForm.get('uploaded_image').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.uploaded_image_url = reader.result as string;
      this.uploadImageForm.get('uploaded_image').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    
    reader.readAsDataURL(file)
  }*/
  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showCropper = true;
    this.showActionButton = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    /* show cropper */
  }
  cropperReady() {
    /* cropper ready */
  }
  loadImageFailed() {
    /* show message */
  }
}
