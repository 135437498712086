import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_services';
import { User } from '../../_models';
import { InfiniteScrollModule, InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users: User[] = [];
  total = 0;
  selectedUser: User; 
  action: string = "";
  loading = false;
  constructor(private userService: UserService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.userService.getAll().subscribe(users => {
      this.users = users;
      if(users)
      this.total = users.length;
    })
  }
  pageChanged() {

  }
  pageChangedup() {

  }
  onSelect(user) {
    this.action = "edit";
    this.selectedUser = user;
  }

  onAddUser() {
    this.action = "edit";
    this.selectedUser = new User();
  }

  onBack() {
    this.action = "";
    this.selectedUser = null;
    this.getAll();
  }
  onChangePassword(id:number): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "changed") {
        this._snackBar.open("User Password changed successfully", "Close", {
          duration: 2000,
        });
      }
    });
  }
}
