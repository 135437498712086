import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PartiesService } from '@app/parties/_services/parties.service';
import { Party } from '@app/parties/_models/party/party';
import { ClientService } from '@app/_services/client.service';
import { LocationService } from '@app/_services';
import { MatInput } from '@angular/material/input';
import { MatStepper } from '@angular/material/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-party-manage',
  templateUrl: './party-manage.component.html',
  styleUrls: ['./party-manage.component.css']
})
export class PartyManageComponent implements OnInit{
  action:string = '';
  loading = false;
  total = 0;
  current_page = 1;
  current_count: 0;
  parties : Party [];
  selectedParty: Party;

  status: any[]=[];
  params = {};
  isBasicComplete = false;
  isDetailedComplete = false;
  isEditable = false;
  panelOpenState = true;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('filterInput', { static: false }) filterInput: MatInput;
  constructor(private partyService: PartiesService, private clientService: ClientService, private locationService: LocationService) { }

  ngOnInit() {
    this.refreshList();
  }
  onSelect(party) {
    this.action = 'view';
    this.selectedParty = party;
  }

  refreshList(forceRefresh: boolean = true) {
    console.log("called");
    this.selectedParty = null;
    this.action = '';
    this.current_page = 0;
    this.getResults();
  }
  getResults() {
    let refresh: boolean = true;
    console.log(this.params);
    this.partyService.search(this.params, this.current_page).subscribe(x => {
      console.log(x);
      this.loading = false;
      this.total = x.total || 0;
      this.current_count = x.to;
      if (x.data) {
        if (refresh === true) {
          this.parties = [];
        }
        x.data.forEach(obj => {
          this.parties.push(obj);
        });
      }
    })
  }
  detectmob() {
    if (window.innerWidth <= 640) {
      return true;
    }
    else {
      return false;
    }
  }
  goForward($event, stepper: MatStepper, $step) {
    switch ($step) {
      case "basic":
        this.isBasicComplete = true;
        break;
      case "detail":
        this.isDetailedComplete = true;
        break;
    }
    stepper.selected.completed = true;
    this.isEditable = true;
    stepper.next();
  }
  tabClick($event) {
    console.log($event.index);
    switch ($event.index) {
      case 0 :
        this.refreshList(true);
        break;
      case 1:
        this.onAddParty();
        break;
    }
    console.log($event);
  }
  tabLoadTimes: Date[] = [];

  getTimeLoaded(index: number) {
    if (!this.tabLoadTimes[index]) {
      this.tabLoadTimes[index] = new Date();
    }

    return this.tabLoadTimes[index];
  }

  onAddParty() {
    this.isBasicComplete = false;
    this.isDetailedComplete = false;
    this.selectedParty = new Party();
    this.action = 'add';
  }
  togglePanel() {
    this.panelOpenState = !this.panelOpenState
  }
  /*onSelect(party) {
    this.action = 'view';
    this.selectedParty = party;
  }*/
  /*getParties(refresh: boolean = true) {
    this.loading = true;
    this.partyService.getAll(this.current_page).subscribe(x => {
      this.loading = false;
      this.total = x.total || 0;
      this.current_count = x.to;
      if (x.data) {
        if (refresh === true) {
          this.parties = [];
        }
        x.data.forEach(element => {
          this.parties.push(element);
        });
      }
    })
  }*/
  /*refreshList(forceRefresh: boolean = true) {
    console.log("called");
    this.selectedParty = null;
    this.action = '';
    this.current_page = 0;
    this.getResults();
  }
  getResults() {
    let refresh: boolean = true;
    console.log(this.params);
    this.partyService.search(this.params, this.current_page).subscribe(x => {
      console.log(x);
      this.loading = false;
      this.total = x.total || 0;
      this.current_count = x.to;
      if (x.data) {
        if (refresh === true) {
          this.parties = [];
        }
        x.data.forEach(obj => {
          this.parties.push(obj);
        });
      }
    })
  }*/




}
