import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WebcamModule} from 'ngx-webcam';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { AttendenceComponent } from './attendence.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';;
@NgModule({
  declarations: [AttendenceComponent],
  exports:[AttendenceComponent],
  imports: [
    CommonModule,
    WebcamModule,
    FormsModule,
   ReactiveFormsModule,
   NgSelectModule,
   FilterPipeModule,
           OwlDateTimeModule, 
        OwlNativeDateTimeModule,


  ]
})
export class AttendenceModule { }
