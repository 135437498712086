export class Connection {
        id: number; 
        model_id: number;
        model_type: string;
        label?: string;
        email?: string;
        mobile?: string;
        landline?: string;
        address_1?: string;
        address_2?: string;
        city_id?:  number;
        state_id?: number;
        city_name?: string;
        state_name?: string;
        full_address?: string;
        pincode?: string;
        kys_record?: boolean=false;
        is_mobile_verified?: boolean;
        is_email_verified?: boolean;
        updated_by?: number;
        deleted_at?: string; 
        created_at?: string; 
        updated_at?: string; 
        profile_complete:any;

        //Old fields :
        type: string;
        name_prefix?: string; 
        first_name?: string; 
        middle_name?: string; 
        last_name?: string; 
        organization?: string; 
        country_code?: string; 
        address?: string; 
        street?: string; 
        state?: string; 
        district?: string; 
        city?: string; 
        postal_code?: string; 
        print_address?: string; 
        mobile2?: string; 
        stdcode?: string; 
        landline2?: string; 
        landline3?: string; 
        landline4?: string; 
        is_primary?: string; 
        lat?: string; 
        lng?: string; 
        entity_type?: string; 
        entity_id?: string; 
        home_address?: string; 
        email2?: string; 
        same_home_address?: string; 
        linked_address?: string; 
        district_id?: string; 
}
