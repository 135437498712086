import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }
    getUser(id) {
      return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
    }
    getUserByEntity(enityType:string,enityId: number) {
    return this.http.get<User>(`${environment.apiUrl}/user/${enityType}/${enityId}`);
    }
    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }
    getAttendanceRegisterars() {
      return this.http.get(`${environment.apiUrl}/users/attendance/registerars`);
    }
 
    create(user: User) {
      console.log(user);
        return this.http.post<User>(`${environment.apiUrl}/user/create`, user);
      }

    update(user: User) {
        return this.http.post<User>(`${environment.apiUrl}/user/update`, user);
      }
    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }
    changePassword(id: number, user: User){
      let field: string = 'password';
      // console.log(user);
        return this.http.post<User>(`${environment.apiUrl}/user/${id}/changePassword/${field}`,user);
    }
}
