import { Component } from '@angular/core';

class MenuItems{
  icon:string;
  label:string;
  link:string;
  permissions:string[];
}

@Component({
  selector: 'app-party-submenu',
  templateUrl: './party-submenu.component.html',
  styleUrls: ['./party-submenu.component.css']
})
export class PartySubmenuComponent {
  menu:MenuItems[]=[
    {icon:"list",label:"Parties",link:"/parties",permissions:['default']},
    {icon:"add",label:"New",link:"/parties/add",permissions:['parties']},
    {icon:"link",label:"Company",link:"/parties/map-company",permissions:['parties']},
    {icon:"settings",label:"Setup",link:"/parties/setup",permissions:['parties']},
  ];
}
