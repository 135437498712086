import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Permission } from '@app/_models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private _permission: BehaviorSubject<Permission[]> = new BehaviorSubject<Permission[]>([]);
  private dataStore: { permissions: Permission[] } = { permissions: [] };

  constructor(private http: HttpClient) {
    this.loadAll();
  }
  get permissions() {
    return this._permission.asObservable();
  }
  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/permission/${id}`);
  }

  loadAll() {
    return this.http.get<Permission[]>(`${environment.apiUrl}/setup/permissions`).subscribe(data => {
      this.dataStore.permissions = data;
      this._permission.next(Object.assign({}, this.dataStore).permissions);
    },
      error => console.log('Could not load permissions.'));
  }

  _getObjectById(id: number): Permission {
    return this.dataStore.permissions.find(x => x.id = id);
  }
}
