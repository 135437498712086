import { Component, OnInit, Input, EventEmitter, SimpleChange, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Role, Module } from '../../../_models';
import { Permission } from '../../../_models/permission';
import { RoleService } from '../../../_services/role.service';
import { Router } from '@angular/router';
import { ModuleService } from '../../../_services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  @Input('role') role: Role = new Role();
  @Output() submitSuccess = new EventEmitter();

  permissions: Array<Permission> = [];
  newAttribute: Permission = new Permission();
  isEditItems: boolean;
  fieldIndex = -1;
  selectedPermissions: any = [];
  addEmployeeForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';
  modules$: Observable<Module[]>;
  constructor(private roleService: RoleService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private moduleService: ModuleService) { }

  ngOnInit() {
    this.modules$ = this.moduleService.modules;
  }
  ngOnChanges(changes: Role) {
    this.permissions = this.role.permissions;
    this.populateFields();
    this.submitted = false;
    this.error = '';
    /*for (const propName in changes) {
      const chng = changes[propName];
      let cur:Role = chng.currentValue;
      const prev = JSON.stringify(chng.previousValue);
      
      //this.changeLog.push(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }*/
  }

  onSaveClick() {
    this.submitted = true;
    console.log(this.f.permissions.value);
    // stop here if form is invalid
    if (this.addEmployeeForm.invalid) {
      return;
    }
    console.log("pass");
    this.loading = true;

    //console.log(this.role.permissions);
    //this.role.name = this.f.rolename.value;
    //this.f.permissions.
    //console.log(this.f.permissions.value);
    //this.role.permissions = this.f.permissions.value;

    let _role: Role = new Role();
    _role.id = this.role.id;
    _role.name = this.f.rolename.value;
    _role.permissions = this.selectedPermissions;
    console.log(this.role);
    this.roleService.create(_role).subscribe(res => {
      this.role = res;
      this.router.navigate(['/setup/roles']);
      this.loading = false;
      this.submitSuccess.emit();
    },
      error => {
        this.error = error;
        this.loading = false;
      });

  }
  addFieldValue(index) {
    const permissions = this.addEmployeeForm.get('permissions') as UntypedFormArray
    permissions.push(this.createPermissionFormGroup());

    /* this.fieldIndex = this.fieldIndex - 1;
     this.newAttribute.id = this.fieldIndex;
     this.newAttribute.name = "";
     this.addEmployeeForm.addControl(this.fieldIndex.toString(), new FormControl('', Validators.required));
     this.permissions.push(this.newAttribute);
     */
    //this.role.permissions.push({ id: this.fieldIndex,name:'' });
    //this.newAttribute = new Permission();
  }
  private createPermissionFormGroup(val = ''): UntypedFormGroup {
    return new UntypedFormGroup({
      'name': new UntypedFormControl(val, Validators.required),
    })
  }
  deleteFieldValue(index) {
    const permissions = this.addEmployeeForm.get('permissions') as UntypedFormArray
    if (permissions.length > 1) {
      permissions.removeAt(index)
    } else {
      permissions.reset()
    }

    //this.role.permissions.splice(index, 1);
  }

  onEditCloseItems() {
    this.isEditItems = !this.isEditItems;
  }

  populateFields() {
    let parry: any = [];
    if (this.permissions.length > 0) {
      this.permissions.forEach(field => {
        parry.push(this.createPermissionFormGroup(field.name))
      });
    } else {
      //parry.push(this.createPermissionFormGroup(''))
    }
    this.addEmployeeForm = this.formBuilder.group({
      rolename: [this.role.name, [Validators.required]],
      permissions: this.formBuilder.array(parry)
    });

    console.log(this.addEmployeeForm);
    /*this.permissions.forEach(field => {
      group[field.name] = new FormControl('', Validators.required)
      
    });
    this.addEmployeeForm = new FormGroup(group);*/
  }
  // convenience getter for easy access to form fields
  get f() { return this.addEmployeeForm.controls; }
  hasPermission(id) {
    let r = this.role.permissions.find(x => x.id === id);
    if (r)
      return true;
    else
      return false;
  }

  onSelectPermission(event) {
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.selectedPermissions.push(event.target.value);
    }
    /* unselected */
    else {
      this.selectedPermissions = this.selectedPermissions.filter(h => h !== event.target.value);
    }
  }
}
