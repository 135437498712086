import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from '../../_services/employee.service';
import { LocationService, ConnectionService } from '../../_services';
import { Connection } from '../../_models';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeDetail } from '../../_models/payroll/employee-detail';
import { Employee } from '@app/_models/payroll';

@Component({
  selector: 'app-add-connection',
  templateUrl: './add-connection.component.html',
  styleUrls: ['./add-connection.component.css']
})
export class AddConnectionComponent implements OnInit {
  @Input('employee') employee: Employee = new Employee();
  @Input('detail') empDetail: EmployeeDetail = new EmployeeDetail();
  @Input('entity') entity: any = {};
  @Input('entityType') entityType: string = "";
  @Input('connection') connection: Connection = new Connection();
  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();
  editConnection: UntypedFormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  error = '';
  today: any = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };//
  states: any = [];
  cities: any = [];
  constructor(private employeeService: EmployeeService,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private connectionService: ConnectionService) { }

  ngOnInit() {
    this.getState();
    this.initForm();
  }
  onSubmit() {
    console.log("called");
    this.submitted = true;
    if (this.editConnection.invalid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    let _connection: Connection = new Connection();
    _connection.model_id = this.entity.id;
    _connection.label = this.f.label.value;
    _connection.email = this.f.email.value;
    _connection.mobile = this.f.mobile.value;
    _connection.landline = this.f.landline.value;
    _connection.address_1 = this.f.address_1.value;
    _connection.address_2 = this.f.address_2.value;
    _connection.state_id = this.f.state_id.value;
    _connection.city_id = this.f.city_id.value;
    _connection.is_mobile_verified = this.f.is_mobile_verified.value;
    _connection.is_email_verified = this.f.is_email_verified.value;
    _connection.is_email_verified = this.f.is_email_verified.value;
    _connection.pincode = this.f.pincode.value;
    _connection.kys_record = this.connection.kys_record;
    console.log(_connection.kys_record);
    if(this.connection.id)
    {
      this.connectionService.update(_connection, this.connection.id).subscribe(x => {
         this.submitted = false;
         this.loading = false;
         // this.submitSuccess.emit(x);
         this.submit.emit();
       },
         error => {
           this.error = error;
           this.submitted = false;
           this.loading = false;
         })
    }else{
      this.connectionService.create(_connection).subscribe(x => {
        // console.log(x);
         this.submitted = false;
         this.loading = false;
         // this.submitSuccess.emit(x);
         this.submit.emit();
       },
         error => {
           this.error = error;
           this.submitted = false;
           this.loading = false;
         })
    } 
  }
  onCancel() {
    console.log("called inside cancel");
    this.cancel.emit();
  }
  onChangesState(event) {
    this.locationService.getCities(event).subscribe(resp => {
      this.cities = resp;
      this.editConnection.patchValue({
        city_id: this.connection.city_id
      });
      this.editConnection.get('city_id').updateValueAndValidity();
    });
  }
  initForm() {
    console.log(this.connection);
    let _dob: NgbDate;
    this.editConnection = this.formBuilder.group({
      label: [this.connection.label, [Validators.required]],
      email: [this.connection.email, [Validators.required,Validators.email]],
      mobile: [this.connection.mobile, [Validators.required]],
      landline: [this.connection.landline],
      address_1: [this.connection.address_1, [Validators.required]],
      address_2: [this.connection.address_2],
      state_id: [this.connection.state_id, [Validators.required]],
      city_id: [this.connection.city_id, [Validators.required]],
      is_mobile_verified: [this.connection.is_mobile_verified, [Validators.required]],
      is_email_verified: [this.connection.is_email_verified, [Validators.required]],
      pincode: [this.connection.pincode, [Validators.required]],
      
      /** old fields */
      // personalEmail: ['', [Validators.email]],
      // personalMobile: ['', [Validators.required]],
      // address1: ['', [Validators.required]],
      // address2: [''],
      // //cityId: [this.empDetail.city_id],
      // cityName: ['', [Validators.required]],
      // stateId: ['', [Validators.required]],
      // stateName: [''],
      // pinCode: ['', [Validators.required]],
    });

  }
  getState() {
    this.locationService.getStates().subscribe(resp => {
      this.states = resp;
    });

  }
  get f() { return this.editConnection.controls; }

  /*getDetails() {
    this.employeeService.getDetail(this.employee.id).subscribe(x => {
      this.empDetail = x;
      this.initForm();
    },
      error => {
        this.initForm();
      });

  }

  
  get f() { return this.editConnection.controls; }
  showPreview(event) {

  }*/

  /*saveData() {
    let _obj: Connection = this.prepareDataToSave();
    this.employeeService.saveDetail(_obj).subscribe(resp => {
      this.submitted = false;
      this.loading = false;
      this.submit.emit(resp);
    }, error => {
      this.error = error;
      this.submitted = false;
      this.loading = false;
    });
  }*/
  /*prepareDataToSave() {
    let _obj: Connection = new Connection();
    _obj.id = this.empDetail ? this.empDetail.id : 0;
    _obj.payroll_employees_id = this.employee.id;
    _obj.father_name = this.f.fatherName.value;
    _obj.pancard = this.f.pancard.value;
    _obj.adharcard_no = this.f.adharcard.value;
    _obj.dob = formatDate(new Date(this.f.dob.value.year, this.f.dob.value.month - 1, this.f.dob.value.day).toDateString(), "y-MM-dd", 'en-US');
    // _obj.identity_proof_image1 = this.f.identity_proof_image1.value;
    // _obj.identity_proof_image2 = this.f.identity_proof_image2.value;
    _obj.email = this.f.personalEmail.value;
    _obj.mobile = this.f.personalMobile.value;
    _obj.address_1 = this.f.address1.value;
    _obj.address_2 = this.f.address2.value;
    _obj.city_id = 0;//this.f.cityId.value;
    _obj.city_name = this.f.cityName.value;
    _obj.state_id = this.f.stateId.value;
    _obj.state_name = this.f.stateName.value;
    _obj.pincode = this.f.pinCode.value;
    return _obj;
  }*/
}
