import { Component, Input,ViewChild } from '@angular/core';
import { Party } from '@app/parties/_models/party/party';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-party-group-edit',
  templateUrl: './party-group-edit.component.html',
  styleUrls: ['./party-group-edit.component.css']
})
export class PartyGroupEditComponent {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  selectedParty :Party = new Party();
  isBasicComplete = false;
  isDetailedComplete = false;
  isEditable = false;

  goForward($event, stepper: MatStepper, $step) {
    switch ($step) {
      case "basic":
        this.isBasicComplete = true;
        break;
      case "detail":
        this.isDetailedComplete = true;
        break;
    }
    stepper.selected.completed = true;
    this.isEditable = true;
    stepper.next();
  }
  refreshList(flag:boolean){

  }
}
