import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Employee } from '../../_models/payroll';
import { EmployeeService } from '../../_services/employee.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployeeDetail } from '../../_models/payroll/employee-detail';
import { formatDate } from '@angular/common';
import { LocationService } from '../../_services';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})
export class EmployeeDetailsComponent implements OnInit {
  @Input('employee') employee: Employee = new Employee();
  @Input('detail') empDetail: EmployeeDetail = new EmployeeDetail();
  @Output() public submit: EventEmitter<EmployeeDetail> = new EventEmitter<EmployeeDetail>();
  @Output() cancel = new EventEmitter();

  editEmployeeDetailForm: UntypedFormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  error = '';
  today: any = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };//
  states: any= [];
  cities: any= [];
  identity_proof_image1_url = '';
  identity_proof_image2_url = '';
  btnSubmitText: string = "Submit";
    is_mobile_verified = null;
is_email_verified = null;
 landline = null;
  constructor(private employeeService: EmployeeService,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService) { }
  ngOnInit() {
    this.identity_proof_image1_url = this.empDetail.identity_proof_image1;
    this.identity_proof_image2_url = this.empDetail.identity_proof_image2;
  }
  ngOnChanges(change: SimpleChange) {
    this.getState();
    this.initForm();
    // this.setConnectionValidations();
    if (this.empDetail.id > 0) {
      this.getDetails();
    } else {
      this.btnSubmitText = "Next";
    }
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.editEmployeeDetailForm.invalid) {
      console.log(this.editEmployeeDetailForm);
      this.loading = false;
      return;
    }
    this.saveData();
  }
  onCancel() {
    this.cancel.emit();
  }
  onChangesState(event) {
    this.locationService.getCities(event).subscribe(resp => {
      this.cities = resp;
      this.editEmployeeDetailForm.patchValue({
        cityId: this.empDetail.city_id
      });
      this.editEmployeeDetailForm.get('cityId').updateValueAndValidity();
    });
  }
  saveData() {
    let _obj:EmployeeDetail =  this.prepareDataToSave();
    this.employeeService.saveDetail(_obj).subscribe(resp => {
      this.submitted = false;
      this.loading = false;
      this.submit.emit(resp);    
    }, error => {
        this.error = error;
        this.submitted = false;
        this.loading = false;
    });
  }
  prepareDataToSave() {
    let _obj: EmployeeDetail = new EmployeeDetail();
    _obj.id = this.empDetail ? this.empDetail.id : 0;
    _obj.payroll_employees_id = this.employee.id;
    _obj.father_name = this.f.fatherName.value;
    _obj.pancard = this.f.pancard.value;
    _obj.adharcard_no = this.f.adharcard.value;
    _obj.dob = formatDate(new Date(this.f.dob.value.year, this.f.dob.value.month - 1, this.f.dob.value.day).toDateString(), "y-MM-dd", 'en-US');
   _obj.identity_proof_image1 = this.f.identityProofImage1.value;
   _obj.identity_proof_image2 = this.f.identityProofImage2.value;
    _obj.email = this.f.personalEmail.value;
    _obj.mobile = this.f.personalMobile.value;
    _obj.address_1 = this.f.address1.value;
    _obj.address_2 = this.f.address2.value;
    _obj.city_id = this.f.cityId.value;
    _obj.city_name = this.f.cityName.value;
    _obj.state_id = this.f.stateId.value;
    _obj.state_name = this.f.stateName.value;
    _obj.pincode = this.f.pinCode.value;
    _obj.is_email_verified = this.f.is_email_verified.value;
    _obj.is_mobile_verified = this.f.is_mobile_verified.value;
    _obj.landline = this.f.landline.value;
    return _obj;
  }
  initForm() {
    let _dob: NgbDate;
    if (this.empDetail.dob) {
      let _dobdt = new Date(this.empDetail.dob);
      _dob = new NgbDate(_dobdt.getFullYear(), _dobdt.getMonth() + 1, _dobdt.getDate());
    }
    
    if (this.empDetail.primary_contact) {
      this.is_mobile_verified = this.empDetail.primary_contact.is_mobile_verified;
      this.is_email_verified = this.empDetail.primary_contact.is_email_verified;
      this.landline  = this.empDetail.primary_contact.landline;
    }
    this.editEmployeeDetailForm = this.formBuilder.group({
      fatherName: [this.empDetail.father_name, [Validators.required]],
      pancard: [this.empDetail.pancard, [Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
      adharcard: [this.empDetail.adharcard_no, [Validators.pattern("^[0-9]{12}$|^[0-9]{16}$")] ],
      dob: [_dob, [Validators.required]],
      identityProofImage1: [this.empDetail.identity_proof_image1],
      identityProofImage2: [this.empDetail.identity_proof_image2],
      personalEmail: [this.empDetail.email, [Validators.email]],
      personalMobile: [this.empDetail.mobile, [Validators.required]],
      address1: [this.empDetail.address_1, [Validators.required]],
      address2: [this.empDetail.address_2],
      cityId: [this.empDetail.city_id, [Validators.required]],
      cityName: [this.empDetail.city_name],
      stateId: [this.empDetail.state_id, [Validators.required]],
      stateName: [this.empDetail.state_name],
      pinCode: [this.empDetail.pincode, [Validators.required]],
      is_mobile_verified: [this.is_mobile_verified, [Validators.required]],
      is_email_verified: [this.is_email_verified],
      landline: [this.landline],
    });

  }
  // setConnectionValidations(){
  //   console.log('inside vlaidatior function');
  //   //this.editEmployeeDetailForm
  //   const is_email_verified = this.editEmployeeDetailForm.get('is_email_verified');
  //   this.editEmployeeDetailForm.get('personalEmail').valueChanges.subscribe(personalEmail => {
  //     if(personalEmail)
  //     {
  //       is_email_verified.setValidators([Validators.required]);
  //     }
  //   })
  // }
  getDetails() {
    this.employeeService.getDetail(this.employee.id).subscribe(x => {
      this.empDetail = x;
      this.initForm();
    },
      error => {
        this.initForm();
      });
      
  }

  getState() {
    this.locationService.getStates().subscribe(resp => {
      this.states = resp;
    });
    
  }
  get f() { return this.editEmployeeDetailForm.controls; }

  // Image Preview
  showPreview1(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.editEmployeeDetailForm.patchValue({
      identity_proof_image1: file
    });
    
    this.editEmployeeDetailForm.get('identityProofImage1').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.identity_proof_image1_url = reader.result as string;
      this.editEmployeeDetailForm.get('identityProofImage1').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    
    reader.readAsDataURL(file)
  }

  // Image Preview
  showPreview2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.editEmployeeDetailForm.patchValue({
      identity_proof_image2: file
    });
    
    this.editEmployeeDetailForm.get('identityProofImage2').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.identity_proof_image2_url = reader.result as string;
      this.editEmployeeDetailForm.get('identityProofImage2').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    
    reader.readAsDataURL(file)
  }
  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }
}
