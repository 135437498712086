import { Component, OnInit } from '@angular/core';
﻿import { Routes, RouterModule,Router } from '@angular/router';
import { AuthenticationService, ModuleService } from '../../../_services';
import { User, Module } from '../../../_models';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  currentUser: User;
  modules: Module[] = [];
  showSideMenu = false;
  constructor(private router: Router, private authenticationService: AuthenticationService) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit() {
  }
  logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
  }

  showMenu() {
    this.showSideMenu = !this.showSideMenu;
  }

}
