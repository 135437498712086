import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { SetupComponent } from './setup.component';
import { SetupRoutingModule } from './setup-routing.module';
import { AddComponent } from './roles/add/add.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AddPortalUserComponent } from './users/add-portal-user/add-portal-user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModulesComponent } from './modules/modules.component';
import { AddModuleComponent } from './modules/add-module/add-module.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { UploadImageComponent } from './users/upload-image/upload-image.component';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { NmhMaterialModule } from '../nmh-material.module';
import { CommondrModule } from '../_commondr/commondr.module';

@NgModule({
  declarations: [UsersComponent, RolesComponent, PermissionsComponent, SetupComponent, AddComponent, AddPortalUserComponent, ModulesComponent, AddModuleComponent, ChangePasswordComponent, UploadImageComponent],
  imports: [
    CommonModule,
    SetupRoutingModule,
    FormsModule,
    ReactiveFormsModule ,
    NgSelectModule,
    ImageCropperModule,
    NmhMaterialModule,
    CommondrModule
  ],
  exports: [
    AddPortalUserComponent,ChangePasswordComponent,UploadImageComponent
  ]

 
})
export class SetupModule { }
