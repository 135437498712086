export class BankDetail{
    id: number;
    payroll_employees_id: number;
    bank_name: string;
    account_number: number;
    ifsc_code: string;
    proof_photo: string|any;
    deleted_at:string;
    created_at:string;
    updated_at:string;
    profile_complete:any;
}