import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, AuthorizationService } from '@app/_services';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.authorizationService.checkPermission("Take Attendance");
      {
        this.router.navigate(['/attendence']);
      }
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          if (data && data.is_super === false && this.authorizationService.checkPermission("Take Attendance")) {
            this.router.navigate(['/attendence-register']);
          } else 
          if (data && data.default_url && data.default_url != "") {
            this.router.navigate([data.default_url]);
          } else {
            if (this.returnUrl == '/') {
              this.router.navigate(['/home']);
            }
            else {
              this.router.navigate([this.returnUrl]);
            }
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
