import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Party } from '@app/parties/_models/party/party';
import { environment } from '@environments/environment';
import { PartyDetails } from '@app/parties/_models/party/party-details';
import { PartyClients } from '@app/parties/_models/party/party-clients';
import { PartyLicence } from '../_models/party/party-licence';
import { PartyGroups } from '../_models/party/party-groups';

@Injectable({ providedIn: 'root' })
export class PartiesService {
    constructor(private http: HttpClient) {}

    getAll(page: number)
    {
        return this.http.get<any>(`${environment.apiUrl}/parties?page=${page}`);
    }
    get(id: number) {
      return this.http.get<Party>(`${environment.apiUrl}/parties/${id}`);
    }
    saveParty(party: Party)
    {
        if(party.id > 0)
        {
            return this.http.post<Party>(`${environment.apiUrl}/party/update`,party);
        } else {
            return this.http.post<Party>(`${environment.apiUrl}/party/create`,party);
        }
    }
    saveDetail(partyDetail: PartyDetails)
    {
        if(partyDetail.id > 0)
        {
          return this.http.post<PartyDetails>(`${environment.apiUrl}/party/detail/update`,partyDetail);
        } else {
          return this.http.post<PartyDetails>(`${environment.apiUrl}/party/detail/create`,partyDetail);
        }
    }
    getDetail(partyId: number)
    {
        return this.http.get<PartyDetails>(`${environment.apiUrl}/party/${partyId}/detail`);
    }
    savePartyClient(partyClient: PartyClients[])
    {
      return this.http.post<PartyClients[]>(`${environment.apiUrl}/party/client/create`, partyClient);
    }
    getPartyClients(partyId: number)
    {
        return this.http.get<PartyClients[]>(`${environment.apiUrl}/party/${partyId}/clients`);
    }
    dropPartyClient(partyClient: PartyClients)
    {
        return this.http.post<PartyClients>(`${environment.apiUrl}/party/client/delete`,partyClient);
    }
    getSearchStatus()
    {
        return this.http.get<any>(`${environment.apiUrl}/parties/search/status`);
    }
    search(params: any,page: number)
    {

        return this.http.post<any>(`${environment.apiUrl}/parties/search?page=${page}`, params);
    }

  getPartyLicence(partyId: number) {
    return this.http.get<PartyLicence>(`${environment.apiUrl}/party/${partyId}/licence`);
  }
  savePartyLicence(partyLicence: PartyLicence) {
    if (partyLicence.id && partyLicence.id > 0) {
      return this.http.post<PartyLicence>(`${environment.apiUrl}/party/${partyLicence.party_id}/licence/${partyLicence.id}`, partyLicence);
    } else {
      return this.http.post<PartyLicence>(`${environment.apiUrl}/party/${partyLicence.party_id}/licence`, partyLicence);
    }
  }
  renewPartyLicence(partyLicence: PartyLicence) {
    if (partyLicence.id && partyLicence.id > 0) {
      return this.http.post<PartyLicence>(`${environment.apiUrl}/party/${partyLicence.party_id}/licence/${partyLicence.id}/renew`, partyLicence);
    }
  }
  deletePartyLicence(partyLicence: PartyLicence) {
    if (partyLicence.id && partyLicence.id > 0) {
      return this.http.delete<any>(`${environment.apiUrl}/party/${partyLicence.party_id}/licence/${partyLicence.id}`);
    }
  }
  getPartyGroups() {
    return this.http.get<PartyGroups[]>(`${environment.apiUrl}/party/groups`)
  }
}
