import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BankDetail } from '@app/_models/payroll/bank-detail';


@Injectable({ providedIn: 'root' })
export class BankDetailService{
    constructor(private http: HttpClient) { }

    create(bankDetail: BankDetail){
      console.log(bankDetail);
        if (bankDetail.id > 0) {
            return this.http.post<BankDetail>(`${environment.apiUrl}/employee/bankDetails/update`, bankDetail);
          } else {
            return this.http.post<BankDetail>(`${environment.apiUrl}/employee/bankDetails/create`, bankDetail);
          }
    }
    get(employeeId: number){
        console.log('inside bank service');
        console.log(employeeId);
        return this.http.get<BankDetail>(`${environment.apiUrl}/employee/${employeeId}/bankDetail`);
    }
}