import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import{Employee } from '@app/_models/payroll';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

@Injectable({ providedIn: 'root' })
export class AttendanceService {
    constructor(private http: HttpClient) { }
    
  getTodayRecords() {
    return this.http.get<any>(`${environment.apiUrl}/employees/attendance/register/current`);
      
  }
  getDayReport(attDate) {
    return this.http.get<any>(`${environment.apiUrl}/employees/attendance/report/day/${attDate}`);

  }
  getStats() {
    return this.http.get<any>(`${environment.apiUrl}/employees/attendance/stats`);
  }
  getDetailAttendance(empId,year,month) {
    return this.http.get<any>(`${environment.apiUrl}/employee/${empId}/attendance/${year}/${month}`);
  }
  getEmployees(status,dt) {
    return this.http.get<any>(`${environment.apiUrl}/employees/attendance/${status}/${dt}`);
  }
    updateTime(employee_id:number,attendance_timestamp:string,proof_image:string) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            }); 
        let params = {"employee_id":employee_id,"attendance_timestamp":attendance_timestamp,"proof_image":proof_image};  
        let options = { headers: headers };
        
        return this.http.post<Employee>(`${environment.apiUrl}/employees/attendance`,params,options);
    }


  getMonthlyReport() {
    let PeriodicElement= [
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H',employeeDesignation:'jj',days:30,late:0,late_15:0,late_30:0,over_time_hours:0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
      { sNo: 1, firmName: 'Hydrogen', employeeName: 'H', employeeDesignation: 'jj', days: 30, late: 0, late_15: 0, late_30: 0, over_time_hours: 0 },
    ];
    return
  }

  getPreRunReport(month,year) {
    return this.http.get<any>(`${environment.apiUrl}/employees/attendance/payrun/${month}/${year}`);
  }
}
