export class Party{
    id:number=0;
    label:string="";
    type:string="";
    unique_number:string="";
    email:string="";
    mobile:string="";
    address_1:string="";
    address_2:string="";
    city_id:number;
    state_id:number;
    city_name:string="";
    state_name:string="";
    pincode:string="";
    kys_info_complete:boolean;
    is_mobile_verified:boolean;
    is_email_verified:boolean;
    has_portal_access:boolean;
    profile_complete:number;
    updated_by:number;
    deleted_at?:string="";
    created_at?:string="";
    updated_at?:string="";
}
