import { Component, OnInit, EventEmitter, Output, Input, SimpleChange } from '@angular/core';
import { Party } from '@app/parties/_models/party/party';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PartiesService } from '@app/parties/_services/parties.service';
import { PartyDetails } from '@app/parties/_models/party/party-details';

@Component({
  selector: 'app-party-details',
  templateUrl: './party-details.component.html',
  styleUrls: ['./party-details.component.css']
})
export class PartyDetailsComponent implements OnInit {
  @Input('party') party: Party = new Party();
  @Input('mode') mode: string = 'view';
  @Output() public submit: EventEmitter<Party> = new EventEmitter<Party>();
  @Output() cancel = new EventEmitter();

  submitted:boolean;
  loading:boolean;
  error:string ='';
  identity_proof_image1_url = '';
  identity_proof_image2_url = '';
  identity_proof_image3_url = '';
  other_document_url = '';

  isEditable = false;

  partyDetail: PartyDetails = new PartyDetails();
  editPartyDetailForm: UntypedFormGroup = this.formBuilder.group({
    first_name: ['', [Validators.required]],
    middle_name: [''],
    last_name: ['',[Validators.required]],
    pan: ['', [Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]$")]],
    adhar: ['', [Validators.pattern("^[0-9]{12}$|^[0-9]{16}$")]],
    gst: ['', [Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]],
    identity_proof_image1: [''],
    identity_proof_image2: [''],
    identity_proof_image3: [''],
    police_station_address: [''],
    other_document: [''],
  });
  constructor(private formBuilder: UntypedFormBuilder, private partiesService: PartiesService) { }
  ngOnChanges(change: SimpleChange) {
    this.setMode(this.mode);
  }
  ngOnInit() {
    console.log("party details");
    console.log(this.mode);
    if (this.mode != 'add') {
      this.getPartyDetail();
    }
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.editPartyDetailForm.invalid) {
      this.loading = false;
      return;
    }
    this.saveData();
  }
  onSkip() {
    this.submit.emit();
  }
  onCancel() {
    if (this.mode != 'add')
      this.isEditable = false;
    this.cancel.emit();
  }

  get f() { return this.editPartyDetailForm.controls; }
  setMode(mode) {
    this.mode = mode;
    this.isEditable = this.mode == 'view' ? false : true;

  }
  initForm() {
    this.editPartyDetailForm.patchValue(this.partyDetail);
  }
  prepareDataToSave() {
    let _obj: PartyDetails = new PartyDetails();
    _obj.id = this.partyDetail ? this.partyDetail.id : 0;
    _obj.party_id = this.party.id;
    _obj.first_name = this.f.first_name.value;
    _obj.middle_name = this.f.middle_name.value;
    _obj.last_name = this.f.last_name.value;
    _obj.identity_proof_image1 = this.f.identity_proof_image1.value;
    _obj.identity_proof_image2 = this.f.identity_proof_image2.value;
    _obj.identity_proof_image3 = this.f.identity_proof_image3.value;
    _obj.pan = this.f.pan.value;
    _obj.adhar = this.f.adhar.value;
    _obj.gst = this.f.gst.value;
    _obj.police_station_address = this.f.police_station_address.value;
    _obj.other_document = this.f.other_document.value;
    return _obj;
  }
  // Image Preview
  showPreview(event,ctrlName) {
    const file = (event.target as HTMLInputElement).files[0];
    this.editPartyDetailForm.patchValue({
      ctrlName: file
    });
    this.editPartyDetailForm.get(ctrlName).updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      switch (ctrlName) {
        case "identity_proof_image1":
          this.identity_proof_image1_url = reader.result as string;
          break;
        case "identity_proof_image2":
          this.identity_proof_image2_url = reader.result as string;
          break;
        case "identity_proof_image3":
          this.identity_proof_image3_url = reader.result as string;
          break;
        case "other_document":
          this.other_document_url = reader.result as string;
          break;
      }
      this.editPartyDetailForm.get(ctrlName).setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    reader.readAsDataURL(file)
  }
  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }
  getPartyDetail() {
    this.partiesService.getDetail(this.party.id).subscribe(data => {
      console.log(data);
      this.partyDetail = data;
      this.identity_proof_image1_url = this.partyDetail.identity_proof_image1;
      this.identity_proof_image2_url = this.partyDetail.identity_proof_image2;
      this.identity_proof_image3_url = this.partyDetail.identity_proof_image3;
      this.other_document_url = this.partyDetail.other_document;
      this.initForm();
    })
  }
  saveData() {
    let _obj: PartyDetails = this.prepareDataToSave();
    this.partiesService.saveDetail(_obj).subscribe(x => {
      this.submitted = false;
      this.loading = false;
      if (this.mode != 'add')
        this.isEditable = false;
      this.partyDetail = x;
      this.submit.emit();
    },
      error => {
        this.error = error;
        this.submitted = false;
        this.loading = false;
      })
  }
}
