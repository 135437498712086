import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttendanceService } from '../../_services';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attendance-employeewise-report',
  templateUrl: './employeewise-report.component.html',
  styleUrls: ['./employeewise-report.component.css']
})
export class EmployeewiseReportComponent implements OnInit {
  @Input('fromDate') fromDate: NgbDate | null;
  @Input('employee_id') employee_id: number
  
  attendace: any;
  constructor(private attendanceService: AttendanceService) { }

  ngOnInit() {

  }

  search() {
    this.getAttendance();
  }
  getAttendance() {
    console.log(this.employee_id);
    let month = this.fromDate.month;
    let year = this.fromDate.year;
    this.attendanceService.getDetailAttendance(this.employee_id, year, month).subscribe(data => {
      this.attendace = data;
    });
  }
  

  getFlagsIcons(flags) {
    let str = "";
    console.log(flags);
    flags = JSON.parse(flags);
    if (flags) {
      flags.forEach(x => {
        switch (x) {
          case "PRESENT":
            str = str + "<i class='fa fa-user text-success mr-2' title='Present'></i>";
            break;
          case "HALF_DAY":
            str = str + "<i class='fa fa-user-circle text-primary mr-2' title='Half Day'></i>";
            break;
          case "LATE_IGNORE":
            str = str + "<i class='fas fa-walking  text-warning mr-2' title='Late Ignored'></i>";
            break;
          case "LATE":
            str = str + "<i class='fas fa-walking  text-danger mr-2' title='Late'></i>";
            break;
          case "EARYLY_LEAVING":
            str = str + "<i class='fas fa-walking  mr-2 text-danger' title='Early Leaving'></i>";
            break;
          case "EARYLY_LEAVING_IGNORE":
            str = str + "<i class='fas fa-walking  mr-2 text-warning' title='Early Leaving Ignore'></i>";
            break;
          case "HALF_DAY_LATE":
            str = str + "<i class='fas fa-walking text-danger mr-2' title='Half Day But Late'></i>";
            break;

        }
      });
    }
    console.log(str);
    return str;
  }
}
