import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class MonthlySalaryReportService {
  constructor(private http: HttpClient) { }
  
  getReport(r_month,r_year) {
    return this.http.get<any>(`${environment.apiUrl}/payroll/report/${r_month}/${r_year}`);
  }
 
}
