import { Component , EventEmitter, Input ,Output} from '@angular/core';
import { Party } from '@app/parties/_models/party/party';

@Component({
  selector: 'app-party-list',
  templateUrl: './party-list.component.html',
  styleUrls: ['./party-list.component.css']
})
export class PartyListComponent {
  @Input('parties') parties:Party[];
  @Output() onEdit = new EventEmitter<Party>();
  @Output() onSelect = new EventEmitter<Party>();
  total:number =0;

  select(party:Party){
    console.log("h");
    this.onSelect.emit(party);
  }
}
