import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbDateParserFormatter, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceService, AuthenticationService } from '../../_services';
import { User } from '../../_models';
import { EmployeewiseReportComponent } from '../../manage-attendance/employeewise-report/employeewise-report.component';

@Component({
  selector: 'app-my-attendance-report',
  templateUrl: './my-attendance-report.component.html',
  styleUrls: ['./my-attendance-report.component.css']
})
export class MyAttendanceReportComponent implements OnInit {

  @ViewChild('employeewiseReport', { static: true, read: EmployeewiseReportComponent})  employeewiseReport: EmployeewiseReportComponent;
  hoveredDate: NgbDate | null = null;
  currentUser: User;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  selectedEmployee: any;
  selectedType: any = "";
  today: NgbDate | null;
  constructor(private attendanceService: AttendanceService,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 7);
    this.today = calendar.getToday();
  }
  
  ngOnInit() {
    this.selectedEmployee = this.currentUser.employee_id;
    this.selectedType = "employee_report";
  }
  ngAfterViewInit() {
    
    this.onSearch();
  }
  onSearch() {
    this.refreshDashboard();
  }
  refreshDashboard() {
    this.employeewiseReport.fromDate = this.fromDate;
    this.employeewiseReport.search();
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
