export class Attendance {
    id:number;
    employee_id:number;
    attendance_date:string;
    in_time:string;
    out_time:string;
    out_datetime:string;
    pending_checkout:boolean;
    updated_by:number;
    created_by:number;
    status:number;
    deleted_at:string;
    created_at:string;
    updated_at:string;
}

