import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  payloadResults = []

  constructor() {
    this.payloadResults.push({
      description: 'Admin Setup!',
      items: {
        title: "",
        icon: "",
        link:""

      }
    })
  }

  ngOnInit() {
  }

}
