import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tennotification',
  templateUrl: './tennotification.component.html',
  styleUrls: ['./tennotification.component.css']
})
export class TennotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
