import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import {PayrollRoutingModule} from './payroll-routing.module';
import { MonthlySalaryReportComponent } from './monthly-salary-report/monthly-salary-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [BankDetailsComponent, MonthlySalaryReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PayrollRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    BankDetailsComponent, MonthlySalaryReportComponent
  ]
})
export class PayrollModule { }
