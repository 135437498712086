import { Component, OnInit } from '@angular/core';
import { InfiniteScrollModule, InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EmployeeService } from '@app/_services/employee.service';
import { Employee, Department, Designation } from '@app/_models/payroll';
import { AddConnectionComponent } from '../connection/add-connection/add-connection.component';
import { EmployeeDetail } from '../_models/payroll/employee-detail';
import { User, Connection } from '../_models';
import { ConnectableObservable, from, Observable } from 'rxjs';
import { DepartmentService } from '../_services/payroll/department.service';
import { DesignationService } from '../_services/payroll/designation.service ';
import { FirmService } from '../_services/payroll/firm.service';
import { Firm } from '../_models/payroll/firm';
import { UserService } from '../_services';
import { UntypedFormControl } from '@angular/forms';

class MenuItems{
  icon:string;
  label:string;
  link:string;
  permissions:string[];
}
@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.css']
})

export class HrComponent implements OnInit {
  loading = false;
  name_filter = "all";
  location_filter = "all";
  employees: Employee[] = [];

  total = 0;
  current_page = 1;
  current_count: 0;
  selectedEmployee: Employee;
  selectedEmployeeDetail: EmployeeDetail;
  selectedUser: User = new User();
  primaryConnection: Connection = new Connection();
  action: string = '';


  managers: User[] = [];
  firms: Observable<Firm[]>;
  departments: Observable<Department[]>;
  status: any;
  namequeryField: UntypedFormControl = new UntypedFormControl();
  params = {};

  menu:MenuItems[]=[
    {icon:"list",label:"Employees",link:"/parties",permissions:['default']},
    {icon:"add",label:"New",link:"/parties/add",permissions:['Add Employee']},
  ];

  constructor(private employeeService: EmployeeService,
    private departmentService: DepartmentService,
    private userService: UserService,
    private firmService: FirmService,
  ) { }

  ngOnInit() {
    this.getEmployees();
    this.populateSearchDropdown();
    this.namequeryField.valueChanges
      .subscribe(val => {
        this.params["emp_name"] = val;
        this.current_page=1;
        this.getResults()
      });
  }

  pageChanged() {
    this.current_page = this.current_page + 1;
    this.getEmployees(false);
  }
  pageChangedup() {

  }
  onSelect(emp) {
    this.action = 'profile';
    this.selectedEmployee = emp;
  }
  onAddEmployee() {
    this.selectedEmployee = new Employee();
    this.action = 'add';
  }
  onAddEmployeeCancel() {
    this.onBack();
  }
  onAddEmployeeSuccess(emp: any) {
    this.action = 'add-detail';
    this.selectedEmployee = emp;
  }
  onBack() {
    this.selectedEmployee = null;
    this.action = '';
    this.current_page = 1;
    this.getEmployees(true);
  }
  onAddDetailSubmit(empDetail: any) {
    this.selectedEmployeeDetail = empDetail;
    this.employeeService.getContacts(this.selectedEmployee.id).subscribe(x => {
      if (x) {
        this.primaryConnection = x[0];
      }
    });
    this.selectedUser = new User();
    this.action = 'add-portal-access';
  }
  onAddDetailCancel() {
    this.selectedUser = new User();
    this.action = 'add-portal-access';
  }
  onAddUserSubmit() {
    this.action = 'add-bank-details';
  }
  onAddUserCancel() {
    this.action = 'add-bank-details';
  }
  onAddConnectionSubmit() {
    this.action = '';
    this.selectedEmployee = null;
    this.selectedUser= null;
    this.selectedEmployeeDetail=null;
    this.getEmployees(true);
  }
  onEditBankDetailSuccess(){
    this.action = 'add-statutory';
  }
  onEditStatutorySuccess(){
    this.action = '';
    this.selectedEmployee = null;
    this.selectedUser= null;
    this.selectedEmployeeDetail=null;
    this.getEmployees(true);
  }
  onEditBankDetailCancel(){
    this.action = '';
  }
  onEditStatutoryCancel(){
    this.action = '';
  }
  onAddContactCancel() {
    this.action = '';
  }
  onConnectionCancel() {

  }
  onChangePassword(emp: any){
    console.log(emp);
    this.action = 'hrChangePassword';
  }
  onChangePasswordCancel(){
    this.action = 'profile';
  }
  onChangePasswordSubmit(emp : any){
    this.action = 'profile';
    this.selectedEmployee = emp;
  }
  getEmployees(refresh: boolean = true) {
    this.getResults(refresh);
   /* this.loading = true;
    this.employeeService.getAll(this.current_page)
      .subscribe(employees => {
        this.loading = false;
        this.total = employees.total || 0;
        this.current_count = employees.to;
        if (employees.data) {
          if (refresh === true) {
            this.employees = [];
          }
          employees.data.forEach(obj => {
            this.employees.push(obj);
          });
        }
      });*/
  }

  populateSearchDropdown() {
    this.userService.getAll().subscribe(d => { this.managers = d });
    this.firms = this.firmService.firms;
    this.departments = this.departmentService.departments;
    console.log(this.firms);
    this.status = this.employeeService.getSearchStatus();
  }
  applyFilter($name, $f) {

    if ($f) {
      this.params[$name] = $f.value;
    } else {
      delete this.params[$name];
    }
    this.current_page = 1;
    this.action = '';
    this.getResults();
    //this.params.filter(x => x.name == $name);
    // this.params.push();
    //let f =  {};
    //this.params.push({ [$name]: $f.name });
    //this.params[$name] = $f;
  }
  getResults(refresh=true) {
    //let refresh: boolean = true;
    this.loading = true;
    //call the search service
    this.employeeService.search(this.params,this.current_page).subscribe(x => {
      console.log(x);
      this.loading = false;
        this.total = x.total || 0;
        this.current_count = x.to;
        if (x.data) {
          if (refresh === true) {
            this.employees = [];
          }
          x.data.forEach(obj => {
            this.employees.push(obj);
          });
        }
    })
  }
}
