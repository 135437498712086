import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrModule } from '../hr/hr.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { CommondrModule } from '../_commondr/commondr.module';
import { ManageAttendanceModule } from '../manage-attendance/manage-attendance.module';
import { MyAttendanceReportComponent } from './my-attendance-report/my-attendance-report.component';
import { NmhMaterialModule } from '../nmh-material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [ProfileComponent, MyAttendanceReportComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    HrModule,
    CommondrModule,
    ManageAttendanceModule,
    NgbModule,
    NmhMaterialModule,
  ]
})
export class ProfileModule { }
