import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {ShiftService} from '@app/_services/payroll/shift.service';
import { formatDate } from '@angular/common';


export interface UsersData {
  name: string;
  id: number;
}

@Component({
  selector: 'app-shift-edit-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent  {

  action: string;
  local_data: any;
  //weekDays = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
  weekDays=[
    {
      name: 'Monday',
      checked: false
    },
    {
      name: 'Tuesday',
      checked: false
    },
    {
      name: 'Wednesday',
      checked: false
    },
    {
      name: 'Thrusday',
      checked: false
    },
    {
      name: 'Friday',
      checked: false
    },
    {
      name: 'Saturday',
      checked: false
    },
    {
      name: 'Sunday',
      checked: false
    }
  ];
  checked: boolean = false;
  picker_in_time:any;
  picker_out_time:any;
  dinner_time:any;
  brkfst_time:any;
  checked_weekdays:string; 
  dataForm:UntypedFormGroup= this.fb.group({       
    name: ['',Validators.required],
    in_time: ['', Validators.required],
    out_time: ['', Validators.required],
    dinner_allowance_time: ['', Validators.required],
    breakfast_allownace_time: ['', Validators.required],   
    min_half_day_hours: ['',Validators.required],
    min_present_hours: ['',Validators.required],    
    is_night_shift:[''],
    weekly_off: ['', Validators.required],
    min_overtime_hours: [null ],  
    });
  
  constructor(
    private fb: UntypedFormBuilder,
    private shiftService: ShiftService,
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) {   

    console.log(data);
    this.local_data = { ...data };
    if(this.local_data)
    {
      if (this.local_data.in_time) {
        this.local_data.in_time = new Date("2020-01-01 " + this.local_data.in_time);
      }
      if (this.local_data.out_time)
        this.local_data.out_time = new Date("2020-01-01 " + this.local_data.out_time);
      if (this.local_data.breakfast_allownace_time)
        this.local_data.breakfast_allownace_time = new Date("2020-01-01 " + this.local_data.breakfast_allownace_time);;
      if (this.local_data.dinner_allowance_time)
      this.local_data.dinner_allowance_time = new Date("2020-01-01 " + this.local_data.dinner_allowance_time);;
      //new Date(this.local_data.in_time);
      if (this.local_data.weekly_off) {
        let selectedOff = this.local_data.weekly_off.split(',');
        console.log(selectedOff);
        this.weekDays.forEach(obj => {
          if (selectedOff.find(x => x==obj.name)) {
            obj.checked = true;
          }
        });
        if (this.local_data.setting) {
          let t = (this.local_data.setting);
          this.local_data.min_overtime_hours = t.min_overtime_hours;
        }
      }
      this.dataForm.patchValue(this.local_data);      
    }    
    this.action = this.local_data.action; 
  }

  doAction() {
    if (this.action == 'Add' || this.action=='Update') {      
      if (this.dataForm.invalid) {
        console.log(this.dataForm);
        return;
      }      
      this.saveData();
    }else if(this.action == 'Delete'){
      console.log('in delete');
      this.deleteData();
    } 
    
  }

  timeChangeHandlerInTime(event) {
    let dt = new Date(event);
    this.dataForm.patchValue({
      in_time: new Date("2020-01-01 " + dt.getHours() + ":" + dt.getMinutes())
    });
    this.dataForm.get('in_time').updateValueAndValidity();

  }

  timeChangeHandlerOutTime(event) {
    let dt = new Date(event);
    this.dataForm.patchValue({
      out_time: new Date("2020-01-01 " + dt.getHours() + ":" + dt.getMinutes())
    });
    this.dataForm.get('out_time').updateValueAndValidity();
  }
  
  timeChangeHandlerDinner(event) {
    let dt = new Date(event);
    this.dataForm.patchValue({
      dinner_allowance_time: new Date("2020-01-01 " + dt.getHours() + ":" + dt.getMinutes())
    });
    this.dataForm.get('dinner_allowance_time').updateValueAndValidity();
  }

  timeChangeHandlerBreakfast(event,controlName) {
    let dt = new Date(event);
    this.dataForm.patchValue({
      breakfast_allownace_time: new Date("2020-01-01 " + dt.getHours() + ":" + dt.getMinutes())
    });
    this.dataForm.get(controlName).updateValueAndValidity();
  }
  
  saveData() {
    let _local_data = this.prepareDataToSave();
    this.shiftService.create(_local_data).subscribe(x => {
      console.log(x);
      //this.local_data = x;
      this.dialogRef.close({ event: this.action, data: x });
    }, error => {
        alert(error);
    });
  }

 get f() { return this.dataForm.controls; }

  prepareDataToSave() {
    let t = this.local_data;
    let _local_data = { ...this.dataForm.value};//Object.assign(t, this.dataForm.value);   
    _local_data.id = t.id ? t.id : 0;
    _local_data.setting = JSON.stringify({ min_overtime_hours: this.f.min_overtime_hours.value });
    _local_data.in_time = new Date(this.f.in_time.value).getHours() + ":" + new Date(this.f.in_time.value).getMinutes();
    _local_data.out_time = new Date(this.f.out_time.value).getHours() + ":" + new Date(this.f.out_time.value).getMinutes();
    _local_data.dinner_allowance_time = new Date(this.f.dinner_allowance_time.value).getHours() + ":" + new Date(this.f.dinner_allowance_time.value).getMinutes();
    _local_data.breakfast_allownace_time = new Date(this.f.breakfast_allownace_time.value).getHours() + ":" + new Date(this.f.breakfast_allownace_time.value).getMinutes();;    
    if (!this.f.is_night_shift.value) { _local_data.is_night_shift = 0; }

   // this.local_data.weekly_off=this.checked_weekdays;
    //console.log(this.local_data);
    return _local_data;
  }

  updateAllChecked() {  
    let wdays = this.weekDays.filter(x => x.checked === true).map(x => x.name);
    let wday = wdays.join(',');
    this.dataForm.patchValue({
      weekly_off: wday
    });
    this.dataForm.get('weekly_off').updateValueAndValidity();
  }

  deleteData() {
    let _local_data = this.prepareDataToSave();
    console.log(_local_data);
    this.shiftService.deleteShift(_local_data).subscribe(x => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
      alert(error);
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
