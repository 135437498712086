import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Holiday } from '../../_models/payroll/holiday';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class HolidayService {
  constructor(private http: HttpClient) { }

  findHolidays(filter = '', sortOrder = 'asc',pageNumber = 0, pageSize = 3): Observable<any> {
    pageNumber = pageNumber + 1;
    return this.http.get(`${environment.apiUrl}/payroll/holidays`, {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('page', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    });
  }
}
