import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Employee } from '../../_models/payroll';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from '../../_services/employee.service';

@Component({
  selector: 'app-employee-resign',
  templateUrl: './employee-resign.component.html',
  styleUrls: ['./employee-resign.component.css']
})
export class EmployeeResignComponent implements OnInit {
  action = "delete";
  local_data: any;
  loading = false;
  submitted = false;

  dataForm = this.fb.group({
    dor: ['', Validators.required]
  })
  constructor(
    public dialogRef: MatDialogRef<EmployeeResignComponent>,
    private fb: UntypedFormBuilder,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: Employee) {
    this.local_data = { ...data };
  }

  ngOnInit() {
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void
  {
    this.dialogRef.close();
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.dataForm.invalid) {
      return;
    }
    if (confirm("Are you sure to delete this employee")) {
      this.loading = true;
      this.employeeService.resignEmployee(this.dataForm.get("dor").value, this.local_data.id).subscribe(resp => {
        this.dialogRef.close("deleted");
        this.loading = false;
        this.submitted = false;
      });
    } else {
      this.loading = false;
      this.submitted = false;
    }
    
  }
}
