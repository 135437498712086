import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { HasRoleDirective } from './has-role.directive';

@NgModule({
  declarations: [HasPermissionDirective, HasRoleDirective],
  imports: [
    CommonModule
  ],
  exports: [HasPermissionDirective, HasRoleDirective]
})
export class CommondrModule { }
