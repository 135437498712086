import { Component, OnInit, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BankDetailService } from '@app/_services/payroll/bank-detail.service';
import { BankDetail } from '@app/_models/payroll/bank-detail';
import { Employee } from '@app/_models/payroll';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  @Input('employee') employee: Employee = new Employee();
  @Input('bankDetails') bDetail : BankDetail = new BankDetail();
  @Output() public submit : EventEmitter<BankDetail> = new EventEmitter<BankDetail>();
  @Output() cancel = new EventEmitter();
  @Input('mode') mode: string = "view";
  editBankDetailsForm: UntypedFormGroup;
  submitted:boolean;
  loading:boolean;
  error:'';
  proof_photo_url='';
  isEditable = false;
  isAddMode = false;

  constructor(private formBuider: UntypedFormBuilder, private bankDetailService: BankDetailService) { }

  ngOnInit() {
    this.proof_photo_url = this.bDetail.proof_photo;
    this.initForm();
  }
  ngOnChanges(change: SimpleChange) {
    this.setMode(this.mode);
  }
  setMode($mode) {
    this.mode = $mode;
    if (this.mode == 'view')
      this.isEditable = false;
    else {
      this.isEditable = true;
    }
  }
  onCancel() {
    this.cancel.emit('cancel');
  }
  onskip() {
    this.submit.emit();
  }
  onSubmit() {
    // console.log('inside submit');
    this.submitted = true;
    this.loading = true;
    if (this.editBankDetailsForm.invalid) {
      console.log(this.editBankDetailsForm);
      this.loading = false;
      return;
    }
    //save bank detail 
    let _objBankDetail : BankDetail = new BankDetail();
    _objBankDetail.id = this.bDetail.id ? this.bDetail.id : 0;
    _objBankDetail.payroll_employees_id = this.employee.id;
    _objBankDetail.bank_name = this.f.bank_name.value;
    _objBankDetail.account_number = this.f.account_number.value;
    _objBankDetail.ifsc_code = this.f.ifsc_code.value;
    _objBankDetail.proof_photo = this.f.proof_photo.value;
    // console.log('inside submit');
    // console.log(_objBankDetail);
    //call api service call
    this.bankDetailService.create(_objBankDetail).subscribe(resp => {
      this.submitted = false;
      this.loading = false;
      // console.log(resp);
      this.submit.emit(resp);
    }, error => {
      this.error = error;
      this.submitted = false;
      this.loading = false;
    }
    );
  }
  initForm(){
    // console.log('inside init');
    // console.log(this.editBankDetailsForm);
    this.editBankDetailsForm =  this.formBuider.group({
      bank_name : [this.bDetail.bank_name,[Validators.required]],
      account_number : [this.bDetail.account_number,[Validators.required]],
      ifsc_code : [this.bDetail.ifsc_code,[Validators.required]],
      proof_photo : [this.bDetail.proof_photo,[Validators.required]],
    });
  }
  get f() { return this.editBankDetailsForm.controls; }
   // Image Preview
   showPreview1(event) {
    const file = (event.target as HTMLInputElement).files[0];
    //console.log(file);
    this.editBankDetailsForm.patchValue({
      proof_photo: file
    });
    
    this.editBankDetailsForm.get('proof_photo').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.proof_photo_url = reader.result as string;
      this.editBankDetailsForm.get('proof_photo').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    
    reader.readAsDataURL(file)
  }

}
