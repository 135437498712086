import {Attendance} from "./attendance";
import { Designation } from './designation';
import { Department } from './department';
import { Firm } from './firm';
import { EmployeeDetail } from './employee-detail';
﻿export class Employee {
    id:number=0;
    first_name:string="";
   middle_name: string = "";
   last_name: string = "";
    emp_id:string;
    gender:string="";
   doj: string = "";
   dor: string = "";
    work_email:string="";
   firm_id: number;
   firm: Firm = new Firm();
    department_id:number;
    designation_id:number;
    reporting_manager_id:number;
    deleted_at:string;
    created_at:string;
    updated_at:string;
   attendance: Attendance = new Attendance();
   profile_image: string|any;
   attendance_manager_id: number;
   department?: Department = new Department();
   designation?: Designation = new Designation();
   total: number;
   to: number;
   reporting_manager: any;
   attendance_manager: any;
   get fullName(): string {
     return this.first_name + " " + this.last_name;
   }
   enable: boolean = false;
   profile_complete=0;
   emp_detail: EmployeeDetail = new EmployeeDetail();
   associated_user_id: number;
}
