import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_helpers';
import { PartiesComponent } from './parties.component';
import { PartyGroupEditComponent } from './party-group-edit/party-group-edit.component';
import { PartyClientsComponent } from './party-clients/party-clients.component';
import { PartyManageComponent } from './party-manage/party-manage.component';
import { PartySearchComponent } from './party-search/party-search.component';
import { PartyViewComponent } from './party-view/party-view.component';




const routes: Routes = [
  {
    path: '',
    component: PartiesComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Parties"] },
    children: [
      {
        path: '',
        component: PartySearchComponent,
        canActivate: [AuthGuard],
        data: { permissions: ["Parties"] }
      },
      {
      path: 'add',
      component: PartyGroupEditComponent,
      canActivate: [AuthGuard],
      data: { permissions: ["Parties"] }
      },
      {
        path: 'edit/:id',
        component: PartyViewComponent,
        canActivate: [AuthGuard],
        data: { permissions: ["Parties"] }
      },
      {
        path: 'search',
        component: PartyClientsComponent,
        canActivate: [AuthGuard],
        data: { permissions: ["Parties"] }
      },
      {
        path: 'map-company',
        component: PartyClientsComponent,
        canActivate: [AuthGuard],
        data: { permissions: ["Parties"] }
      },
    ]
}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartyRoutingModule { }
