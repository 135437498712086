import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartiesComponent } from './parties.component';
import { PartyEditComponent } from './party-edit/party-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartyDetailsComponent } from './party-details/party-details.component';
import { PartyViewComponent } from './party-view/party-view.component';
import { PartyClientsComponent, PartyClientsDialog } from './party-clients/party-clients.component';
import { PartyLicenceComponent, PartyLicenceDialog } from './party-licence/party-licence.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { NmhMaterialModule } from '../nmh-material.module';
import { CommondrModule } from '../_commondr/commondr.module';

import { DeviceDetectorService } from 'ngx-device-detector';
import { PartyRoutingModule } from './party-routing.module';
import { PartyListComponent } from './party-list/party-list.component';
import { PartyGroupEditComponent } from './party-group-edit/party-group-edit.component';
import { PartyManageComponent } from './party-manage/party-manage.component';
import { PartySearchComponent } from './party-search/party-search.component';
import { PartySubmenuComponent } from './party-submenu/party-submenu.component';


@NgModule({
    declarations: [PartiesComponent, PartyEditComponent, PartyDetailsComponent, PartyViewComponent, PartyClientsComponent, PartyLicenceComponent, PartyLicenceDialog, PartyClientsDialog, PartyListComponent, PartyGroupEditComponent, PartyManageComponent, PartySearchComponent, PartySubmenuComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        NmhMaterialModule,
        CommondrModule,
        PartyRoutingModule
    ]
})
export class PartiesModule { }
