import { Directive, OnInit, ElementRef, TemplateRef, ViewContainerRef, Input, Attribute } from '@angular/core';
import { AuthenticationService} from '@app/_services';
import { User } from '@app/_models';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private currentUser: User;
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
      this.authenticationService.currentUser.subscribe(x => {
          this.currentUser = x;
          this.updateView();
      });
  }

  @Input()
  set appHasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {

    let hasPermission = false;
    if (this.currentUser && this.currentUser.is_super === true) {
      return true;
    }

    if (this.currentUser && this.currentUser.permissions) {

      for (const checkPermission of this.permissions) {
        //default permission is for home/ dashboard Menu Items
        let permissionFound = false
        if(checkPermission.toUpperCase() ==  'DEFAULT'){
          permissionFound = true;
        }else{
          permissionFound = this.currentUser.permissions.find(x => x.toUpperCase() === checkPermission.toUpperCase());
        }
        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }
}

