import { Component, OnInit,Directive, EventEmitter, Output, } from '@angular/core';
﻿import { Routes, RouterModule,Router } from '@angular/router';
import { AuthenticationService } from '../../../_services';
import { User } from '../../../_models';
import { MatBottomSheet } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  @Output() public sidenavToggle = new EventEmitter();
  constructor(private router: Router, private authenticationService: AuthenticationService, private worklist: MatBottomSheet) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  ngOnInit() { }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
