import { Component, OnInit } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime/picker';
import{Employee,Attendance } from '@app/_models/payroll';
import { AttendanceService ,AuthorizationService} from '@app/_services';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { NgSelectConfig } from '@ng-select/ng-select';
import { FormControl,ReactiveFormsModule } from '@angular/forms';   
import { OrderModule } from 'ngx-order-pipe';


@Component({
  selector: 'app-attendence',
  templateUrl: './attendence.component.html',
  styleUrls: ['./attendence.component.css']
})
/**
 * 
 * @todo : Get Stats from database and update the same on save click
 * @todo : UI fixes for mobile and desktop
 * 
 */
export class AttendenceComponent implements OnInit {
  
  public currentMoment = new Date();
  attendance_records_all: Employee[]=[];
  attendance_records: Employee[]=[];  
  public display_date = new Date();
  public pending_checkout_timestamp = new Date();
  public searchEmployee:string="";
  showPendingLogoutPupup = false;
  loading = false;
   namequeryField:any; 
  selectedName: any;
  constructor(private attendanceService: AttendanceService,private authorizationService:AuthorizationService,private config: NgSelectConfig) { 
    console.log("in in");
    this.authorizationService.checkPermission("Take Attendance");
  }

  ngOnInit() {
          setInterval(() => {
       this.display_date = new Date();
    }, 1000);
          this.refreshRecords("in");
            WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
  nameChanged(event)
    {
     
        if(event != null){
            this.attendance_records.filter(x=>x.first_name=event);
        }else
        {
            this.attendance_records;
        }
        console.log(this.selectedName);
        console.log(event);
    }
  public current_employee:any = null;
  public current_employee_index:number = 0;
  
    refreshRecords(tab:string)
    {
        this.loading = true;
        this.attendanceService.getTodayRecords().subscribe(records=>{
          //console.log(records);
            
            this.attendance_records_all = records;
            this.attendance_records = records["in"]; 
            if(tab=="in")
                this.attendance_records = records["in"]; 
            else
                this.attendance_records = records["out"]; 
                this.loading = false;
      },error=>{
                    this.loading = false;
      });

        
    }
    startAttendanceProcess(rec:any,event:any,row_index:number)
    {
        this.showPendingLogoutPupup = false;
        this.pending_checkout_timestamp = rec.attendance ? rec.attendance.attendance_date : new Date() ;
        if(rec.pending_checkout == true)
        {
            this.loading = true;
            this.showPendingLogoutPupup = true;  
            this.showWebcam = false;
            this.attendanceSuccess= false;
            this.current_employee = rec;
            this.current_employee_index = row_index;
            this.loading = false;
            
        }else
        {
            this.showPendingLogoutPupup = false;  
            this.loading = true;
            this.showWebcam = true;
            this.attendanceSuccess= false;
            this.current_employee = rec;
            this.current_employee_index = row_index;
            this.loading = false;
        }
    }
    
    triggerPendingCheckout()
    {
            this.showPendingLogoutPupup = false;  
            this.loading = true;
            this.showWebcam = true;
            this.attendanceSuccess= false;
            //this.current_employee = rec;
            //this.current_employee_index = row_index;
            this.loading = false;

    }
    RestartAttenadence()
    {
        this.showPendingLogoutPupup = false;
        this.showWebcam = false;
        this.attendanceSuccess= false;
        this.webcamImage = null;
    }
    
    updateAttendance(event:any)
    {
        this.loading=true;
        if(this.current_employee)
        {
            
            //console.log(this.webcamImage.imageAsDataUrl);
            let attendance_datetime:any = new Date();
            if(this.current_employee && this.current_employee.pending_checkout)
            {
                attendance_datetime=this.pending_checkout_timestamp;
            }
                this.attendanceService.updateTime(this.current_employee.id,attendance_datetime,this.webcamImage.imageAsDataUrl).subscribe(entities => {
                this.attendance_records.splice(this.current_employee_index,1);
                //moveItemInArray(this.attendance_records_all, this.current_employee_index, this.attendance_records.length - 1);
                this.attendanceSuccess= true;
                setTimeout(()=>{    //<<<---    using ()=> syntax
                    this.RestartAttenadence();
               }, 3000);
                this.loading = false;    
            },error=>{this.loading = false;});
        }
        
    }
    
    public showWebcam = false;
    public allowCameraSwitch = false;
    public multipleWebcamsAvailable = false;
    public attendanceSuccess = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public triggerSnapshot(): void {
    this.trigger.next();
  }

 
  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
      console.log(error);
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
        //console.info('received webcam image', webcamImage);
        this.webcamImage = webcamImage;
        this.showWebcam = false;

  }

  public cameraWasSwitched(deviceId: string): void {
    //console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
    
    
}
