import { Component, OnInit, Input, SimpleChange, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { PartiesService } from '@app/parties/_services/parties.service';
import { Party } from '@app/parties/_models/party/party';
import { LocationService } from '@app/_services';


@Component({
  selector: 'app-party-edit',
  templateUrl: './party-edit.component.html',
  styleUrls: ['./party-edit.component.css']
})
export class PartyEditComponent implements OnInit {
  @Input('party') party: Party = new Party();
  @Input('mode') mode: string = "view";

  @Output() public submit: EventEmitter<Party> = new EventEmitter<Party>();
  @Output() cancel = new EventEmitter();
  action:string = '';
  submitted:boolean;
  loading:boolean;
  error:string ='';

  states: any= [];
  cities: any= [];
  public lblUniqueNumber:string = 'Unique Number';
  public type:any;
  isEditable = false;
  isAddMode = false;
  categories = ['Registered party', 'Doctor', 'UnRegistered', 'Composition', 'E-commerce operators'];

  editPartyForm: UntypedFormGroup = this.formBuilder.group({
    label: ['', [Validators.required]],
    type: [null, [Validators.required]],
    unique_number: [''],
    email: ['', [Validators.email]],
    mobile: ['', [Validators.required]],
    address_1: ['', [Validators.required]],
    address_2: [''],
    city_id: [null, [Validators.required]],
    city_name: [''],
    state_id: [null, [Validators.required]],
    state_name: [''],
    pincode: ['', [Validators.required]],
    is_mobile_verified: [false],
    is_email_verified: [false],
    has_portal_access: [false],
  });
  constructor(private formBuilder: UntypedFormBuilder, private partiesService: PartiesService, private locationService: LocationService) { }

  ngOnChanges(change: SimpleChange) {
    this.populateMasters();
    this.setMode(this.mode);
  }

  ngOnInit() {
    console.log("edit party");
    if (this.mode != 'add') {
      this.getParty();
    }
  }

  onchangePartyType($event) {
    switch ($event) {
      case 'Registered party':
        this.lblUniqueNumber = 'GST Number';
        this.editPartyForm.get('unique_number').setValidators(Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"));
        this.editPartyForm.get('unique_number').updateValueAndValidity();
        break
      case 'Composition':
        this.lblUniqueNumber = 'GST Number';
        this.editPartyForm.get('unique_number').setValidators([Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]);
        this.editPartyForm.get('unique_number').updateValueAndValidity();
        break;
      case 'Doctor':
        this.lblUniqueNumber = 'PAN';
        this.editPartyForm.get('unique_number').setValidators([Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]$")]);
        this.editPartyForm.get('unique_number').updateValueAndValidity();
      case 'UnRegistered':
        this.lblUniqueNumber = 'PAN';
        this.editPartyForm.get('unique_number').setValidators([Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]$")]);
        this.editPartyForm.get('unique_number').updateValueAndValidity();
        break;
      case 'E-commerce operators':
        this.editPartyForm.get('unique_number').clearValidators();
        this.editPartyForm.get('unique_number').updateValueAndValidity();
        this.lblUniqueNumber = 'RegisterationID';
        break;
    }
  }

  onChangeState($event) {
    this.locationService.getCities($event).subscribe(resp => {
      this.cities = resp;
      this.editPartyForm.patchValue({
        city_id: this.party.city_id
      });
      this.editPartyForm.get('city_id').updateValueAndValidity();
    });
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.editPartyForm.invalid) {
      this.loading = false;
      return;
    }
    this.saveData();
  }
  onCancel() {
    this.isEditable = false;
    this.cancel.emit();
  }

  get f() { return this.editPartyForm.controls; }

  setMode($mode) {
    this.mode = $mode;
    if (this.mode == 'view')
      this.isEditable = false;
    else {
      this.isEditable = true;
    }
  }
  populateMasters() {
    this.getState();
  }

  prepareDataToSave(){
    let _obj: Party = new Party();
    _obj.id = this.party ? this.party.id : 0;
    _obj.label = this.f.label.value;
    _obj.type = this.f.type.value;
    _obj.unique_number = this.f.unique_number.value;
    _obj.email = this.f.email.value;
    _obj.mobile = this.f.mobile.value;
    _obj.address_1 = this.f.address_1.value;
    _obj.address_2 = this.f.address_2.value;
    _obj.state_id = this.f.state_id.value;
    _obj.city_id = this.f.city_id.value;
    _obj.pincode = this.f.pincode.value;
    _obj.is_mobile_verified = this.f.is_mobile_verified.value;
    _obj.is_email_verified = this.f.is_email_verified.value;
    _obj.has_portal_access = this.f.has_portal_access.value;
    return _obj;
  }
  initForm() {
    this.editPartyForm.patchValue(this.party);
  }
  getParty() {
    this.partiesService.get(this.party.id).subscribe(data => {
      this.party = data;
      this.editPartyForm.patchValue(data);
      this.editPartyForm.updateValueAndValidity();
    },
    );

  }
  getState() {
    this.locationService.getStates().subscribe(resp => {
      this.states = resp;
    });
  }
  saveData() {
    let _obj: Party = this.prepareDataToSave();
    this.partiesService.saveParty(_obj).subscribe(x => {
      this.submitted = false;
      this.loading = false;
      this.error = "";
      this.party = x;
     if(this.mode != 'add')
      this.isEditable = false;
      this.submit.emit(x);
    },
      error => {
        console.log(error);
        this.error = error;
        this.submitted = false;
        this.loading = false;
      })
  }

}
