import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Designation } from '../../_models/payroll/designation';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DesignationService {
  private _designations: BehaviorSubject<Designation[]> = new BehaviorSubject<Designation[]>([]);
  private dataStore: { designations: Designation[] } = { designations: [] };
  //readonly designations = this._designations.asObservable();

  constructor(private http: HttpClient) {
    this.loadAll();
  }
  get designations() {
    return this._designations.asObservable();
  }
  loadAll() {
    return this.http.get<Designation[]>(`${environment.apiUrl}/payroll/setup/designations`).subscribe(data => {
      this.dataStore.designations = data;
      this._designations.next(Object.assign({}, this.dataStore).designations);
    },
      error => console.log('Could not load designations.'));
  }
}
