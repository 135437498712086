import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService} from './authentication.service';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {

    currentUser: User;
    constructor(private authenticationService: AuthenticationService,        private route: ActivatedRoute,
        private router: Router,
) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    checkPermission(name:string)
    {
        if (this.currentUser && this.currentUser.is_super === true) {
          return true;
        }
        if(this.currentUser && this.currentUser.permissions)
        {
            let per =  this.currentUser.permissions;
            if(per.indexOf(name) == -1)
            {
                this.router.navigate(["/login"]);
            }
        }else{
        this.router.navigate(["/login"]);}
      return true;    
    }
    hasPermission(name: string) {
      if (this.currentUser && this.currentUser.is_super === true) {
        return true;
      }
      if (this.currentUser && this.currentUser.permissions) {
        let per = this.currentUser.permissions;
        if (per.indexOf(name) == -1) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    }
    
    
}
