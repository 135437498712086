import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_helpers';
import { MonthlySalaryReportComponent } from './monthly-salary-report/monthly-salary-report.component';


const routes: Routes = [
  
  {
    path: 'monthlyreport',
    component: MonthlySalaryReportComponent,
    canActivate: [AuthGuard]   
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollRoutingModule { }
