import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../_services/role.service';
import { Role } from '@app/_models';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  roles: Role[] = [];
  selectedRole: Role;
  action: string = "";
  loading = false;
  constructor(private roleService: RoleService) { }
  ngOnInit() {
   
    this.getAll();
  }

  getAll() {
    this.roleService.getAll().subscribe(roles => {
      this.roles = roles;
      console.log(this.roles);
    })
  }

  onSelect(role) {
    this.action = "edit";
    this.selectedRole = role;
  }

  onAddRole() {
    this.action = "edit";
    this.selectedRole = new Role();
  }
  
  onBackToList() {
    //console.log(this.selectedRole);
    //this.roles.push(this.selectedRole);
    this.action = "";
    this.selectedRole = null;
    this.getAll();
  }
}
