import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Party } from '@app/parties/_models/party/party';
import { PartyDetails } from '@app/parties/_models/party/party-details';
import { PartiesService } from '@app/parties/_services/parties.service';
import { PartyClients } from '@app/parties/_models/party/party-clients';
import { ClientService } from '@app/_services/client.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-party-view',
  templateUrl: './party-view.component.html',
  styleUrls: ['./party-view.component.css']
})
export class PartyViewComponent implements OnInit {
  @Input('party') party: Party = new Party();
  @Output() public submit: EventEmitter<Party> = new EventEmitter<Party>();
  @Output() cancel = new EventEmitter();
  action:string = '';
  submitted:boolean;
  loading:boolean;
  error:string ='';
  editEnable: boolean = false;
  activeTab:string = 'basic';
  detail : PartyDetails;
  partyClients: PartyClients;
  partyLicences: PartyClients;
  profile_complete:any;

  constructor(private partyService: PartiesService,
              private clientService: ClientService,private route: ActivatedRoute) { }

  ngOnInit() {
    /*this.route.params.subscribe(params => {
      console.log(params);
      let id = params['id'];
      this.party.id = id;
      console.log(id);
    });*/
    if(this.party)
    {
      this.profile_complete = this.party.profile_complete;
    }
    //this.getDetail();
    //this.getClient();

  }



  onEditPartyBasic() {
    this.action = 'edit-basic';
    this.editEnable = true;
    // this.activeTab = 'basic';
  }
  onEditPartyDetail() {
    this.action = 'edit-detail';
    this.editEnable = true;
    this.activeTab = 'detail';
  }
  onEditPartyBasicSuccess(party: any) {
    this.party = party;
    if(this.party)
    {
      this.profile_complete = this.party.profile_complete;
    }
    this.resetView();
    this.activeTab = 'basic';
  }
  onEditPartyBasicCancel() {
    this.resetView();
    this.activeTab = 'basic';
  }
  onEditPartyDetailSuccess(partyDel: any) {
    this.detail = partyDel;
    this.activeTab = 'detail';
    this.resetView();
  }
  onEditPartyDetailCancel() {
    this.resetView();
    this.activeTab = 'detail';
  }
  resetView() {
    this.editEnable = false;
    this.action = '';
  }
  onBack() {
    this.cancel.emit();
  }
  applyClass(col) {
    if (this.editEnable) {
      switch (this.action) {
        case "edit-basic":
        case "edit-client":
        case "edit-portal-access":
        case "edit-connection":
        case "edit-detail":
          if (col == "right")
            return "col-sm-12";
          else
            return "d-none";
          break;
      }
    } else {
      if (col == "left") {
        return "col-sm-4 col-xs-12";
      } else {
        return "col-sm-8 col-xs-12";
      }
    }
  }
  getBadgeClass() {
    if (this.party) {
      if (this.party.kys_info_complete)
        return "badge badge-success";
    }
    return "badge badge-danger";
  }
}
