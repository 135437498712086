import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { CommondrModule } from '../_commondr/commondr.module';


@NgModule({
    imports: [
    CommonModule,
    LayoutModule,
    CommondrModule,
   
  ],
  
})

export class SharedModule { }
