import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, UntypedFormBuilder, Validators, UntypedFormGroup,FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import {ShiftService} from '@app/_services/payroll/shift.service';
import { EmployeeService } from '@app/_services/employee.service';
import { Employee} from '@app/_models/payroll';
import { formatDate } from '@angular/common';
import { NgbDate, NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';  
import { PayrollEmployeeShift } from '@app/_models/payroll/payroll-employee-shift';
import {PayrollEmployeeShiftService} from '@app/_services/payroll/payroll-employee-shift.service';




export interface UsersData {
  name: string;
  id: number;
}

@Component({
  selector: 'app-associate-shift-edit-dialog-box',
  templateUrl: './associate-shift.component.html',
  styleUrls: ['./associate-shift.component.css']
})
export class AssociateShiftComponent  {

  action: string;
  local_data: any;
  name:string;
  shift_id:number;
  //employees:any[];
  employees: Employee[] = [];
  checked: boolean = false;
  today: any = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };//
 
  dataFormAS:UntypedFormGroup= this.fb.group({      
    employee_list: ['',Validators.required],
    start_date: ['',Validators.required],
    end_date: ['',Validators.required]
    });
   // date = new FormControl(new Date());
  
  constructor(
    private fb: UntypedFormBuilder,
    private shiftService: ShiftService,
    private employeeService: EmployeeService,
    public dialogRef: MatDialogRef<AssociateShiftComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) {   

    console.log(data);
    this.local_data = { ...data };
    if(this.local_data)
    {
     // this.dataFormAS.patchValue(this.local_data);      
    }    
    this.action = this.local_data.action;     
    this.name=this.local_data.name;
    this.shift_id=this.local_data.id;
    console.log(this.shift_id);
  }

  ngOnInit() {
  this.getEmployeeList();   
  }

  getEmployeeList() {
    this.employeeService.getList().subscribe(x => { 
      if(x){
        console.log(x);
        this.employees = x;
      }
     // console.log(this.employees);      
    });
  }

  doAction() {
    if (this.action == 'AssociateShift') {      
      if (this.dataFormAS.invalid) {
       // console.log(this.dataFormAS);
        return;
      }      
      this.saveData();
    } 
    if (this.action == 'DissociateShift') {
      if (this.dataFormAS.invalid) {
        // console.log(this.dataFormAS);
        return;
      }
      this.disaccociate();
    } 
  }

  saveData() {
    let _local_data = this.prepareDataToSave();
    
    _local_data.employee_list.forEach((item) => {
      console.log(item);
      //call the service 
      _local_data.employee_id = item;
      this.shiftService.attachToEmployee(_local_data.employee_id, _local_data.shift_id, _local_data.start_date, _local_data.end_date).subscribe(x => {
          console.log(x);         
          //this.dialogRef.close({ event: this.action, data: x });
        }, error => {
            alert(error);
        });
      });      
      this.dialogRef.close({ event: this.action, data: _local_data });
  }
  disaccociate() {
    let _local_data = this.prepareDataToSave();

    _local_data.employee_list.forEach((item) => {
      console.log(item);
      //call the service 
      _local_data.employee_id = item;
      this.shiftService.dettachFromEmployee(_local_data.employee_id, _local_data.shift_id, _local_data.start_date, _local_data.end_date).subscribe(x => {
        console.log(x);
        //this.dialogRef.close({ event: this.action, data: x });
      }, error => {
        alert(error);
      });
    });
    this.dialogRef.close({ event: this.action, data: _local_data });
  }

 get f() { return this.dataFormAS.controls; }

  prepareDataToSave() {
    let t = this.local_data;   
    let _local_data = { ...this.dataFormAS.value}; 
   _local_data.shift_id=t.id;
    _local_data.start_date = formatDate(new Date(_local_data.start_date).toDateString(), "y-MM-dd", 'en-US');
   _local_data.end_date = formatDate(new Date(_local_data.end_date).toDateString(), "y-MM-dd", 'en-US');
    return _local_data;
  }
  
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
