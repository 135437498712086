import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Connection, ConnectionEntities,ConnectionSearch} from '@app/_models';

@Injectable({ providedIn: 'root' })
export class MarketreturnService {
    constructor(private http: HttpClient) { }

    getHomeWidgetData() {
        return this.http.get<any[]>(`${environment.apiUrl}/home/marketreturn`);
      
    }
     private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
    
}