import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddConnectionComponent } from './add-connection/add-connection.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NmhMaterialModule } from '../nmh-material.module';

@NgModule({
  declarations: [AddConnectionComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NmhMaterialModule
  ],
  exports: [
    AddConnectionComponent
    ]
})
export class ConnectionModule { }
