import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { HolidayDataSource } from './holiday-datasource';
import { MatDialog } from '@angular/material/dialog';
import { HolidayEditComponent } from './holiday-edit/holiday-edit.component';
import { HolidayService } from '../../_services/payroll/holiday.service';
import { tap } from 'rxjs/operators';
import { Holiday } from '../../_models/payroll/holiday';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css'],
  providers: [HolidayService]
})
export class HolidayComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<Holiday>;

  dataSource: HolidayDataSource;
  displayedColumns = ['id', 'holidate','name','action'];

  constructor(public dialog: MatDialog,private holidayService: HolidayService) {
  }

  ngOnInit() {
    this.dataSource = new HolidayDataSource(this.holidayService);
    this.dataSource.loadHolidays();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.paginator.page.pipe(tap(() => this.loadHolidayPage())).subscribe();
  }
  loadHolidayPage() {
    this.dataSource.loadHolidays(
      '',
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(HolidayEditComponent, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        //this.addRowData(result.data);
      } else if (result.event == 'Update') {
        //this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        //this.deleteRowData(result.data);
      }
    });
  }
}
