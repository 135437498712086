import { Component, OnInit, Input, Directive, Output, EventEmitter } from '@angular/core';
import { EmployeeService } from '../../_services/employee.service';
import { Employee } from '../../_models/payroll';
import { User } from '../../_models';
import { EmployeeDetail } from '../../_models/payroll/employee-detail';
import { Connection } from '../../_models/connection'; 
import { ConnectionService } from '@app/_services';
import { BankDetail } from '@app/_models/payroll/bank-detail';
import { BankDetailService } from '@app/_services/payroll/bank-detail.service';
import { EmployeeStatutory } from '@app/_models/payroll/employee-statutory';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { EmployeeResignComponent } from '../employee-resign/employee-resign.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangePasswordComponent } from '../../setup/users/change-password/change-password.component';
@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.css']
})
export class EmployeeProfileComponent implements OnInit {
  @Input('employee') employee: Employee;
  @Input('connection') connection: Connection;
  @Output() cancel = new EventEmitter();
  detail: EmployeeDetail;
  user: User;
  contact: Connection[];
  action: string = ''
  loading = false;
  editEnable: boolean = false; 
  activeTab: string = 'profile';
  bank: BankDetail;
  statutory: EmployeeStatutory;
  profile_complete:any;
  isMobile = false;
  //employee: Employee;
  submitted = false;
  constructor(private employeeService: EmployeeService, private connectionService: ConnectionService, private bankDetailService: BankDetailService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  onDeleteEmployee(): void {
    const dialogRef = this.dialog.open(EmployeeResignComponent, {
      width: '500px',
      data:  this.employee 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "deleted") {
        this._snackBar.open("Employee Deleted Successfully", "Close", {
          duration: 2000,
        });
        this.onBack();
      }
    });
  }

  onRevokeEmployee(): void {
    this.submitted = true;
    if (confirm("Are you sure to restore this employee")) {
      this.loading = true;
      this.employeeService.revokeEmployee(this.employee.id).subscribe(resp => {
          this.employee.dor = null;
          this.loading = false;
          this.submitted = false;
          this._snackBar.open("Employee Restored Successfully", "Close", {
            duration: 2000,
          });
        this.onBack();
      }, error => {
        this._snackBar.open(error, "Close", {
          duration: 4000,
        });
      });
    } else {
      this.loading = false;
      this.submitted = false;
    }
  }
  onChangePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: this.employee.associated_user_id
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "changed") {
        this._snackBar.open("Employee Password changed successfully", "Close", {
          duration: 2000,
        });
      }
    });
  }
   
  ngOnInit() {
    this.isMobile = this.detectmob();
  }
  detectmob() {
    if (window.innerWidth <= 640) {
      return true;
    }
    else {
      return false;
    }
  }
  ngOnChanges(changes: Employee) {
    this.getEmployee();
  }
  getEmployee() {
    this.loading = true;
    this.employeeService.get(this.employee.id).subscribe(x => {
      this.loading = false;
      this.employee = x;
      this.profile_complete = this.employee.profile_complete;
    });
  }
  getDetail() {
    if (this.employee && !this.detail) {
      this.loading = true;
      this.employeeService.getDetail(this.employee.id).subscribe(x => {
        this.loading = false;
        this.detail = x;
        this.profile_complete = this.detail.profile_complete.profile_complete;
      }, error => {
          this.loading = false;
          this.detail = new EmployeeDetail();
      })
    } 
  }
  getContacts() {
    //if (this.employee && !this.contact) {
      this.loading = true;
      this.employeeService.getContacts(this.employee.id).subscribe(x => {
        this.loading = false;
        this.contact = x;
      })
    //} 
  }
  getUser() {
    if (this.employee && !this.user) {
      this.loading = true;
      this.employeeService.getUser(this.employee.id).subscribe(x => {
        this.user = x;
        this.loading = false;
     },
       error => {
          this.loading = false;
      this.user = new User();
    });
    }
  }
  getStatutory() {
    if(this.employee && !this.statutory)
    {
      this.loading = true;
      this.employeeService.getEmployeeStatutory(this.employee.id).subscribe(x => {
        console.log(x);
        this.statutory = x;
        // this.profile_complete = this.statutory.profile_complete.profile_complete;
        this.loading = false;
      })

    }
  }
  onBack() {
    //this.employee = null;
    this.cancel.emit();
  }
  onEditBasic()
  {
    this.action = 'edit-basic';
    this.editEnable = true;
    this.activeTab = 'profile';
    this.getEmployee();
  }
  onEditEmployeeSuccess(emp: Employee) {
    this.employee = emp;
    this.profile_complete = this.employee.profile_complete;
    this.action = '';
    this.activeTab = 'profile';
  }
  onEditBasicCancel(emp: Employee) {
    this.resetView();
    this.activeTab = 'profile';
  }
  onEditDetail() {
    this.resetView();
    this.action = 'edit-detail';
    this.editEnable = true;
  }
  onEditDetailSuccess(emp: any) {
    this.detail = emp;
    this.profile_complete = this.detail.profile_complete;
    this.activeTab = 'personal';
    this.resetView();
  }
  onEditDetailCancel() {
    this.activeTab = 'personal';
    this.resetView();
  }
  resetView() {
    this.editEnable = false;
    this.action = '';
  }
  onEditPortalAccess() {
    this.action = 'edit-portal-access';
    this.editEnable = true;
    this.activeTab = 'portal';
  }
  onEditPortalSubmit(user: User) {
    //this.getUser();
    console.log(user);
    this.user = user;
    this.resetView();
    this.activeTab = 'portal';
  }
  onEditPortalCancel() {
    this.resetView();
    this.activeTab = 'portal';
  }

  onEditConnection(connection) {
    this.connection = connection ? connection : new Connection();
    this.action = 'edit-connection';
    this.editEnable = true;
    
  }
  onEditBankDetails() {
    this.action = 'edit-bankDetails';
    this.editEnable = true;
    this.activeTab = 'bank';
  }
  getBankDetail(){
    if(this.employee && !this.bank)
    {
      this.loading = true;
      this.bankDetailService.get(this.employee.id).subscribe(resp => {
        this.loading = false;
        this.bank = resp;
      }, error => {
          this.loading = false;
          this.bank = new BankDetail();
      })
    }
  }
  onEditBankDetailCancel(){
    this.resetView();
    this.activeTab = 'bank';
  }
  onEditBankDetailSuccess(bankSuccess: any){
    this.bank = bankSuccess;
    console.log(this.bank);
    this.resetView();
    this.activeTab = 'bank';
  }
  onEditConnectionSuccess(){
    this.employeeService.getContacts(this.employee.id).subscribe(x => {
      this.loading = false;
      this.contact = x;
    })
    this.action = 'edit-connection';
    this.activeTab = 'contact';
   this.resetView();
  }
  onEditConnectionSubmit() {
    this.resetView();
    this.action = 'edit-connection';
  }
  onEditConnectionCancel() {
    this.resetView();
    this.activeTab = 'contact';
  }
  onConnectionDrop(connectionId){
    console.log(connectionId);
    this.connectionService.drop(connectionId).subscribe(x =>{
      this.loading = false;
    })
    this.getContacts();
    this.action = '';
    this.activeTab = 'contact';
    this.resetView();
  }
  onChangePasswordOld(){
    
    //this.resetView();
    
    if (this.employee && !this.user) {
      this.loading = true;
      this.employeeService.getUser(this.employee.id).subscribe(x => {
        this.user = x;
        this.action = 'changePassword';
        //this.editEnable = true;
        //this.activeTab = 'portal';
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.user = new User();
        });
    } else {
      this.action = 'changePassword';
      //this.editEnable = true;
      //this.activeTab = 'portal';
    }
    
  }
  onChangePasswordCancel(){
    this.resetView();
    //this.action = 'changePassword';
    //this.activeTab = 'portal';
  }
  onChangePasswordSubmit(user: User){
    this.user = user;
    this.resetView();
    //this.activeTab = 'portal';
  }
  onUploadProfileImage(){
    this.action = 'upload-profile-image';
    this.activeTab = 'profile';
    console.log(this.action);
    //this.resetView();
  }
  onEditProfileImageCancel(){
    this.resetView();
    this.activeTab = 'portal';
  }
  onEditProfileImageSuccess(emp : Employee){
    console.log('insdie profile component');
    console.log(emp);
    this.employee.profile_image = emp.profile_image;
  }
  
  applyClass(col) {
    if (this.editEnable) {
      switch (this.action) {
        case "edit-basic":
          if (col == "right")
            return "col-sm-12";
          else
            return "d-none";
          break;
        case "edit-portal-access":
        case "edit-bankDetails":
        case "edit-portal-access":
        case "edit-connection":
        case "edit-detail":
          if (col == "right")
            return "col-sm-12";
          else
            return "d-none";
          break;
      }
    } else {
      if (col == "left") {
        return "col-sm-4 col-xs-12";
      } else {
        return "col-sm-8 col-xs-12";
      }
    }
  }
  getBadgeClass() {
    if (this.profile_complete) {
      if (this.profile_complete == '100')
        return "badge badge-success";
    }
    return "badge badge-danger";
  }
  
}
