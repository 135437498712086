import { Component, Input, Output, EventEmitter, ViewChild, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Employee } from '@app/_models/payroll';
import { EmployeeService } from '@app/_services/employee.service';
import { EmployeeStatutory } from '@app/_models/payroll/employee-statutory';
import { StatutoryService } from '@app/_services/payroll/statutory.service';
//import { stringify } from 'querystring';

@Component({
  selector: 'app-employee-statutory',
  templateUrl: './employee-statutory.component.html',
  styleUrls: ['./employee-statutory.component.css']
})
export class EmployeeStatutoryComponent implements OnInit  {
  @Input() employee: Employee = new Employee();
  @Output() public submit: EventEmitter<Employee> = new EventEmitter<Employee>();
  @Output() cancel = new EventEmitter();

  @ViewChild(MatTable, { static: true }) table: MatTable<EmployeeStatutory>;

  displayedColumns: string[] = ['type_id', 'details', 'action'];
  dataSource: any;

  constructor(public dialog: MatDialog,private employeeService: EmployeeService) {

  }

  ngOnInit() {
    this.getData();
  }
  openDialog(action, obj) {
    obj.action = action;
    obj.employee_id = this.employee.id;
    const dialogRef = this.dialog.open(EmployeeStatutoryDialog, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  getData() {
    this.employeeService.getEmployeeStatutory(this.employee.id).subscribe(data => {

      this.dataSource = data;
    });

  }
  addRowData(row_obj) {
    var d = new Date();
   let _local_data: EmployeeStatutory = new EmployeeStatutory();
   _local_data = Object.assign(_local_data, row_obj);
   this.dataSource.push(_local_data);
   this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value = Object.assign(value, row_obj);
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
  }

}

@Component({
  selector: 'employee-statutory-dialog',
  templateUrl: 'employee-statutory-dialog.html',
})
export class EmployeeStatutoryDialog {

  action: string;
  local_data: any;
  detail: any;
  categories:any[] = [];
  sample : any[] = [];
  dataForm = this.fb.group({
    type_id: [null, Validators.required],
    statutory_number: [null],
    statutory_document: [null, Validators.required],
  });
  statutory_document_url = "";

  constructor(private fb: UntypedFormBuilder, private employeeService: EmployeeService,
    private licenceService: StatutoryService,
    public dialogRef: MatDialogRef<EmployeeStatutoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeStatutory) {
    this.populateMaster();
    this.local_data = { ...data };
    if(this.local_data.details)
    {
      let details = this.local_data.details;
      this.local_data.statutory_number =  details? details.statutory_number:"";
      this.dataForm.patchValue(this.local_data);
      this.statutory_document_url = details? details.statutory_document: "";
    }
    this.action = this.local_data.action;
    // console.log(this.statutory_document_url);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    console.log(this.action);
    if (this.action == 'Add' || this.action == 'Update') {
      if (this.dataForm.invalid) {
        return;
      }
      this.saveData();
    }
    else if(this.action == 'Delete') {
      {
        console.log('in dleet');
        this.deleteData();
    }}
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  showPreview(event, ctrlName) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    console.log(file);
    this.dataForm.patchValue({
      statutory_document: file
    });
    this.dataForm.get(ctrlName).updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
          this.statutory_document_url = reader.result as string;
    this.dataForm.get(ctrlName).setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    reader.readAsDataURL(file)
  }
  get f() { return this.dataForm.controls; }
  prepareDataToSave() {
    let t = this.local_data;
    this.local_data = Object.assign(this.local_data, this.dataForm.value);
    console.log(this.local_data);
    this.local_data.id =t.id ? t.id:0;
    this.local_data.payroll_employees_id = t.employee_id;
    this.local_data.details  =    {statutory_number: this.local_data.statutory_number,statutory_document :  this.local_data.statutory_document } ;
     console.log(this.local_data);
  }
  populateMaster() {
    if (this.categories.length == 0) {
      this.licenceService.getStatutory().subscribe(x => {
        this.categories = x;

      });
    }

  }
  saveData() {
    this.prepareDataToSave();
    this.employeeService.saveEmployeeStatutory(this.local_data).subscribe(x => {
      console.log(x);
      this.local_data = x;
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
        alert(error);
    });
  }
  deleteData() {
    this.prepareDataToSave();
    this.employeeService.deleteEmployeeStatutory(this.local_data).subscribe(x => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
      alert(error);
    });
  }
}
