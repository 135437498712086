import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { MarketreturnService, AttendanceService, AuthorizationService, AuthenticationService } from '@app/_services';
import {TimeAgoPipe} from 'time-ago-pipe';
import { EmployeeService } from '../_services/employee.service';
import { User } from '../_models';
import { Employee } from '../_models/payroll';
import { Observable } from 'rxjs';

@Component({ templateUrl: 'home.component.html',styleUrls: ['./home.component.css'] })
export class HomeComponent implements OnInit, OnDestroy{
    loading = false;
    marketreturns: any[];
    appSettings: any[];
    mrLastUpadtedAt: number;
    employeeStats$: Observable<any>;
    attendanceStats: any;
    currentUser: User;
    profile_complete: boolean = false;
    todayDate: Date =  new Date();
  constructor(private employeeService: EmployeeService, private attendanceService: AttendanceService,
    private authService: AuthorizationService, private authenticationService: AuthenticationService) {



    if (authService.hasPermission("Manage Attendance")) {
      this.getAttendanceStats();
    }

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.currentUser.employee_id)
    this.getMyProfile();
  }

  ngOnInit() {
    this.loading = true;
    this.getEmployeeStats();
  }

  getEmployeeStats(refresh = false) {
    this.employeeService.getStats(refresh)
   .subscribe(data => {
            this.employeeStats$ = data;
    });
  }

  getAttendanceStats() {
    this.attendanceService.getStats().subscribe(x => {
      this.attendanceStats = x;
    });
  }
  getMyProfile() {
    this.employeeService.get(this.currentUser.employee_id).subscribe(x => {
      this.profile_complete = x.enable;
    });
  }

  ngOnDestroy() {

  }
}
