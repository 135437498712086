import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { PendingApprovalDataSource, PendingApprovalItem } from './pending-approval-datasource';
import { MatDialog } from '@angular/material/dialog';
import { AttendanceService } from '../../_services';
import { AttPendingDialogBoxComponent } from './att-pending-dialog-box/att-pending-dialog-box.component';

@Component({
  selector: 'app-attendance-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.css']
})
export class PendingApprovalComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<PendingApprovalItem>;
  dataSource: PendingApprovalDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'att_date', 'in_image', 'in_time', 'out_image', 'out_time', 'updated_at', 'action'];

  constructor(public dialog: MatDialog,
    private attendanceService: AttendanceService
  ) {

  }
  ngOnInit() {
    this.dataSource = new PendingApprovalDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  openDialog(action, obj) {
    console.log(obj);
    obj.action = action;
    const dialogRef = this.dialog.open(AttPendingDialogBoxComponent, {
      data: obj,
    });
  }
}
