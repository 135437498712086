import { Component, OnInit } from '@angular/core';
﻿import { Routes, RouterModule,Router } from '@angular/router';
import { AuthenticationService, UserService } from '@app/_services';
import { User } from '@app/_models';
import { EmployeeService } from '../_services/employee.service';
import { Employee } from '../_models/payroll';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: User;
  employee: Employee;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private employeeService: EmployeeService) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }
  ngOnInit() {
    if (this.currentUser.employee_id) {
      this.getMyProfile();
    }
  }
  getMyProfile() {
    this.employee = new Employee();
    this.employee.id = this.currentUser.employee_id;
    this.employee.work_email = "";
  }

}
