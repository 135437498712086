import { Component, Input, Output, EventEmitter, ViewChild, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Party } from '../_models/party/party';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { PartiesService } from '../_services/parties.service';
import { PartyClients } from '../_models/party/party-clients';
import { ClientService } from '../../_services/client.service';
import { Observable } from 'rxjs';
import { Client } from '../../_models/client';


@Component({
  selector: 'app-party-clients',
  templateUrl: './party-clients.component.html',
  styleUrls: ['./party-clients.component.css']
})
export class PartyClientsComponent implements OnInit {
  @Input() party: Party = new Party();
  @Output() public submit: EventEmitter<Party> = new EventEmitter<Party>();
  @Output() cancel = new EventEmitter();

  @ViewChild(MatTable, { static: true }) table: MatTable<PartyClients>;

  displayedColumns: string[] = ['merged_client','group_name' ,'cheque_count', 'security_cheque_num', 'credit_period','third_party_code' ,'action'];
  dataSource: any;

  constructor(public dialog: MatDialog, private partyService: PartiesService) {

  }

  ngOnInit() {
    this.getData();
  }
  openDialog(action, obj) {
    obj.action = action;
    obj.party_id = this.party.id;
    const dialogRef = this.dialog.open(PartyClientsDialog, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  getData() {
    this.partyService.getPartyClients(this.party.id).subscribe(data => {
      this.dataSource = data;
    });

  }
  addRowData(row_obj) {
    var d = new Date();
    let _local_data: PartyClients = new PartyClients();
    _local_data = Object.assign(_local_data, row_obj);
    this.dataSource.push(_local_data);
    this.table.renderRows();

  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value = Object.assign(value, row_obj);
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
  }

}

@Component({
  selector: 'party-clients-dialog',
  templateUrl: 'party-clients-dialog.html',
})
export class PartyClientsDialog {
  action: string;
  local_data: any;
  clients$: Observable<Client[]>;
  groups: any[] = [];
  salesmans: any[] = [];
  dataForm = this.fb.group({
    client_id: [null, Validators.required],
    party_groups_id: [null, Validators.required],
    cheque_count: [0, Validators.required],
    security_cheque_num: null,
    client_salesman_id: null,
    credit_period: 0,
    third_party_code: null

  });

  constructor(private fb: UntypedFormBuilder, private partyService: PartiesService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<PartyClientsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PartyClients) {
    this.populateMaster();
    this.local_data = { ...data };
    this.dataForm.patchValue(this.local_data);
    this.action = this.local_data.action;
    console.log(this.local_data);
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }
  onSubmit() {
    console.log(this.action);
    if (this.action == 'Add' || this.action == 'Update') {
      if (this.dataForm.invalid) {
        return;
      }
      this.saveData();
    }
    else if (this.action == 'Delete') {
      {
        this.deleteData();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  get f() { return this.dataForm.controls; }
  prepareDataToSave() {
    let t = this.local_data;
    this.local_data = Object.assign(this.local_data, this.dataForm.value);
    this.local_data.id = t.id ? t.id : 0;
    this.local_data.party_id = t.party_id;
    console.log(this.local_data);
  }
  populateMaster() {
    if (this.groups.length == 0) {
      this.partyService.getPartyGroups().subscribe(x => {
        this.groups = x;

      });
    }
    this.clients$ = this.clientService.clients;
  }
  onClientChange($event) {
    this.clientService.getClientEmployees($event).subscribe(x => {
      this.salesmans = x;
    });
  }
  saveData() {
    this.prepareDataToSave();
    let d: PartyClients[] = [this.local_data];
    this.partyService.savePartyClient(d).subscribe(x => {
      this.local_data = x.filter(z => z.client_id == this.local_data.client_id);
      this.dialogRef.close({ event: this.action, data: this.local_data[0] });
    }, error => {
      alert(error);
    });
  }
  deleteData() {
    this.prepareDataToSave();
    this.partyService.dropPartyClient(this.local_data).subscribe(x => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
      alert(error);
    });
  }
}
