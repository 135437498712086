import { Component, OnInit } from '@angular/core';
import { Module } from '../../_models';
import { ModuleService } from '../../_services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {
  modules$: Observable<Module[]>;
  selectedModule: Module;
  action: string = "";
  loading = false;
  constructor(private moduleService: ModuleService) { }
  ngOnInit() {
    this.modules$ = this.moduleService.modules;
  }
  
  onSelect(module) {
    this.action = "edit";
    this.selectedModule = module;
  }

  onAddModule() {
    this.action = "edit";
    this.selectedModule = new Module();
  }

  onBackToList() {
    this.action = "";
    this.selectedModule = null;
    this.moduleService.refresh();
  }
}
