import { Component } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { AttendanceService } from '../../_services';
import { Employee } from '../../_models/payroll';

@Component({
  selector: 'app-attendance-day-report',
  templateUrl: './attendance-day-report.component.html',
  styleUrls: ['./attendance-day-report.component.css']
})
export class AttendanceDayReportComponent {
  dayReport: any;
  presents: Employee;
  absents: Employee;
  fromDate: NgbDate | null;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['first_name', 'in_image','in_time','out_image' , 'out_time', 'updated_at','status'];

  constructor(private attendanceService: AttendanceService) { }

  showDayReport(date) {
    this.fromDate = date;
    this.getDayReport();
    this.getEmployeeAbsent();
  }
  getDayReport() {
    let dt = formatDate(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toDateString(), "y-MM-dd", 'en-US');
    this.attendanceService.getDayReport(dt).subscribe(x => {

      this.dayReport = x;
      console.log(this.dayReport);
    });
  }
  getEmployeePresent() {
    let dt = formatDate(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toDateString(), "y-MM-dd", 'en-US');
    this.attendanceService.getEmployees('present', dt).subscribe(x => {
      this.presents = x;
    });
  }
  getEmployeeAbsent() {
    let dt = formatDate(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toDateString(), "y-MM-dd", 'en-US');
    this.attendanceService.getEmployees('absent', dt).subscribe(x => {
      this.absents = x;
    });
  }
}
