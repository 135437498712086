import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Firm } from '../../_models/payroll/firm';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FirmService {
  private _firms: BehaviorSubject<Firm[]> = new BehaviorSubject<Firm[]>([]);
  private dataStore: { firms: Firm[] } = { firms: [] };
  
  constructor(private http: HttpClient) {
    this.loadAll();
  }
  get firms() {
    return this._firms.asObservable();
  }
  loadAll() {
    return this.http.get<Firm[]>(`${environment.apiUrl}/payroll/setup/firms`).subscribe(data => {
      this.dataStore.firms = data;
      this._firms.next(Object.assign({}, this.dataStore).firms);
    },
    error => console.log('Could not load firms.'));
  }

  _getObjectById(id: number): Firm {
    return this.dataStore.firms.find(x => x.id = id);
  }
}
