import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../_services/date-formatter.service';
import { ManageAttendanceComponent } from './manage-attendance.component';
import { EmployeewiseReportComponent } from './employeewise-report/employeewise-report.component';
import { AttendanceDayReportComponent } from './attendance-day-report/attendance-day-report.component';
import { ShiftComponent } from './shift/shift.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DialogBoxComponent } from './shift/dialog-box/dialog-box.component';
import { AssociateShiftComponent } from './shift/associate-shift/associate-shift.component';
import { HolidayComponent } from './holiday/holiday.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NmhMaterialModule } from '../nmh-material.module';
import { HolidayEditComponent } from './holiday/holiday-edit/holiday-edit.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { AttPendingDialogBoxComponent } from './pending-approval/att-pending-dialog-box/att-pending-dialog-box.component';
import { ManageAttendanceRoutingModule } from './manage-attendance-routing.module';
import { AttendanceMenuComponent } from './attendance-menu/attendance-menu.component';
import { MatTimepickerModule } from 'mat-timepicker';
import { AttendancePayrunComponent } from './attendance-payrun/attendance-payrun.component';
import { CommondrModule } from '@app/_commondr/commondr.module';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
    declarations: [ManageAttendanceComponent, EmployeewiseReportComponent, AttendanceDayReportComponent, ShiftComponent, DialogBoxComponent, AssociateShiftComponent, HolidayComponent,
        HolidayEditComponent,
        PendingApprovalComponent,
        AttPendingDialogBoxComponent,
        AttendanceMenuComponent,
        AttendancePayrunComponent,
        DashboardComponent
    ],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NmhMaterialModule,
        CommondrModule,
        ManageAttendanceRoutingModule,
        MatTimepickerModule
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
    exports: [
        EmployeewiseReportComponent
    ]
})
export class ManageAttendanceModule { }
