import { Directive,OnInit,ElementRef,TemplateRef,ViewContainerRef,Input,Attribute } from '@angular/core';
import { AuthenticationService} from '@app/_services';
import { User } from '@app/_models';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  private currentUser: User;
  private roles = [];
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService
  ) {
        
  }

  ngOnInit() {
      this.authenticationService.currentUser.subscribe(x => {
          this.currentUser = x;
          this.updateView();
      });
  }

  @Input()
  set appHasRole(val) {
    this.roles = val;
    this.updateView();
  }

  @Input()
  set hasRoleOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkRole()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkRole() {
    
    let hasRole = false;
    if (this.currentUser && this.currentUser.is_super === true) {
      return true;
    }
      
    if (this.currentUser && this.currentUser.roles) {
        
      for (const checkRole of this.roles) {
         //console.log(checkRole.toUpperCase());
          //console.log(this.currentUser.roles);
        const roleFound = this.currentUser.roles.find(x => x.toUpperCase() === checkRole.toUpperCase());
        
        if (roleFound) {
          hasRole = true;
          //console.log(this.currentUser);
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasRole = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }
    
    return hasRole;
  }
}

