import { User } from '../../../_models';

export class PartyClients {
    id:number=0;
    party_id:number;
    client_id:number;
    party_groups_id:number;
    cheque_count:number=0;
    security_cheque_num:number;
    client_salesman_id:number;
    credit_period:number=0;
    third_party_code:string="";
    created_at?:string="";
    updated_at?:string="";
    partei:any;
    partycli:any;
    partygrp:any;
    partysalesman:any;
    updated_by: User = new User();
}
