import { NgModule } from '@angular/core';
﻿import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { CommondrModule } from '../../_commondr/commondr.module';
import { WorkListComponent } from './work-list/work-list.component';
import { NmhMaterialModule } from '../../nmh-material.module';

@NgModule({
    declarations: [FooterComponent, HeaderComponent, MenuComponent, WorkListComponent],
    imports: [
        CommonModule,
        RouterModule,
        CommondrModule,
        NmhMaterialModule
    ],
    exports: [FooterComponent, HeaderComponent, MenuComponent, WorkListComponent]
})
export class LayoutModule { }
