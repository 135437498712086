import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { SetupComponent } from './setup.component';
import { AuthGuard } from '../_helpers';
import { ModulesComponent } from './modules/modules.component';



const routes: Routes = [
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { permissions:["User"]}
  },
  {
    path: 'modules',
    component: ModulesComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Module"] }
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["User"] }
  },
  {
    path: 'permissions',
    component: PermissionsComponent,
    canActivate: [AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetupRoutingModule { }
