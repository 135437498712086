import { User } from '..';
import { Statutory } from './statutory';

export class EmployeeStatutory {
    id:number = 0;
    payroll_employees_id:number;
    type_id:number;
    details: any = { statutory_number: "", statutory_document:""};
    created_by: User = new User();
    updated_by: User = new User();
    deleted_at:string;
    created_at:string;
    updated_at:string;
    statutory: Statutory = new Statutory();
    profile_complete:any;
}
