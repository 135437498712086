export class Location {
        id: number; 
        name: string; 
        country_id?: number; 
        state_id?:number    
        deleted_at?: string; 
        created_at?: string; 
        updated_at?: string; 
        country_name?: string; 
        state_name?: string; 
        latitude?: string; 
        longitude?: string; 

}