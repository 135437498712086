import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeProfileComponent } from './employee-profile/employee-profile.component';
import { HrComponent } from './hr.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SetupModule } from '../setup/setup.module';
import { ConnectionModule } from '../connection/connection.module';
import { CommondrModule } from '../_commondr/commondr.module';
import { NgbDateCustomParserFormatter } from '../_services/date-formatter.service';
import { PayrollModule } from '@app/payroll/payroll.module';
import { NmhMaterialModule } from '../nmh-material.module';
import { EmployeeStatutoryComponent, EmployeeStatutoryDialog } from './employee-statutory/employee-statutory.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EmployeeResignComponent } from './employee-resign/employee-resign.component';
import { ChangePasswordComponent } from '../setup/users/change-password/change-password.component';


@NgModule({
    declarations: [EmployeeDetailsComponent, EmployeeProfileComponent, HrComponent, EmployeeEditComponent, EmployeeStatutoryComponent, EmployeeStatutoryDialog, EmployeeResignComponent
    ],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SetupModule,
        ConnectionModule,
        CommondrModule,
        PayrollModule,
        NmhMaterialModule,
        ImageCropperModule
    ],
    exports: [
        EmployeeProfileComponent
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class HrModule { }
