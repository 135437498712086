import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Module } from '@app/_models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModuleService {
  private _modules: BehaviorSubject<Module[]> = new BehaviorSubject<Module[]>([]);
  private dataStore: { modules: Module[] } = { modules: [] };

  constructor(private http: HttpClient) {
    this.loadAll();
  }
  get modules() {
    return this._modules.asObservable();
  }

  loadAll() {
    return this.http.get<Module[]>(`${environment.apiUrl}/setup/modules`).subscribe(data => {
      this.dataStore.modules = data;
      this._modules.next(Object.assign({}, this.dataStore).modules);
    },
      error => console.log('Could not load permissions.'));
  }

  _getObjectById(id: number): Module {
    return this.dataStore.modules.find(x => x.id = id);
  }
  refresh() {
    this.loadAll();
  }
  create(module: Module) {
        let obj: any;
        obj = module;
        obj.permissions = JSON.stringify(module.permissions);
    return this.http.post<Module>(`${environment.apiUrl}/setup/module`, obj);
    }

    delete(id: number) {
      return this.http.delete(`${environment.apiUrl}/module/${id}`);
    }
  
}
