import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Connection, ConnectionEntities,ConnectionSearch} from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
    constructor(private http: HttpClient) { }

    getAll() {
      return this.http.get<any>(`${environment.apiUrl}/contacts`);
    }
    getEntities() {
      return this.http.get<ConnectionEntities[]>(`${environment.apiUrl}/contacts/models`);
    }
    getEntityFilters(entity_id:number) {
      return this.http.get<any[]>(`${environment.apiUrl}/contacts/${entity_id}/filters`);
    }
    search(name: string,location: string,page:number,params:ConnectionSearch) {
        if(name =="")name = "all";
        if(location =="")location = "all";
      return this.http.post<any>(`${environment.apiUrl}/contacts/search/${name}/${location}?page=${page}`,params);
    }

     private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    create(connection: Connection) {
        console.log(connection);
        return this.http.post<Connection>(`${environment.apiUrl}/employee/contact/create`, connection);
      }
    update(connection: Connection, contactId: number) {
        console.log(connection);
        console.log(contactId);
        return this.http.post<Connection>(`${environment.apiUrl}/employee/contact/update/${contactId}`, connection);
      }
    drop(contactId: number){
      console.log(contactId);
      return this.http.get<Connection>(`${environment.apiUrl}/employee/contact/drop/${contactId}`);
    }
}
