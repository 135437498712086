import { Component, OnInit } from '@angular/core';

class MenuItems{
  icon:string;
  label:string;
  link:string;
  permissions:string[];
}

@Component({
  selector: 'app-attendance-menu',
  templateUrl: './attendance-menu.component.html',
  styleUrls: ['./attendance-menu.component.css']
})
export class AttendanceMenuComponent implements OnInit {
  menu:MenuItems[]=[
    {icon:"dashboard",label:"Dashboard",link:"/attendance",permissions:['Manage Attendance']},
    {icon:"rule",label:"Approvals",link:"/attendance/approvals",permissions:['Manage Attendance']},
    {icon:"sync_alt",label:"Shift",link:"/attendance/shifts",permissions:['Manage Attendance']},
    {icon:"deck",label:"holidays",link:"/attendance/holidays",permissions:['default']},
    {icon:"memory",label:"Payrun",link:"/attendance/payrun",permissions:['Manage Attendance']},
  ];

  constructor() { }

  ngOnInit() {
  }

}
