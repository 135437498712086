import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Employee } from '@app/_models/payroll';
import { EmployeeDetail } from '../_models/payroll/employee-detail';
import { User } from '../_models';
 import { Connection } from '../_models/connection';
import { EmployeeStatutory } from '@app/_models/payroll/employee-statutory';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  private _employees: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>([]);
  private _employeeDashboardStats: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private dataStore: { employees: Employee[] } = {employees : [] };

  constructor(private http: HttpClient, private authService: AuthorizationService) {
      
  }

  loadEmployees(page: number) {
    return this.http.get<any>(`${environment.apiUrl}/employees?page=${page}`).subscribe(data => {
      this.dataStore.employees = data.data;
      this._employees.next(data);
    });
  }
  getAll(page: number) {
    return this.http.get<any>(`${environment.apiUrl}/employees?page=${page}`);
  }
  search(params: any, page: number) {
    return this.http.post<any>(`${environment.apiUrl}/employee/search?page=${page}`, params);
  }

  loadDashboardStats() {
    this.http.get<any>(`${environment.apiUrl}/employees/stats`).subscribe(data => {
      this._employeeDashboardStats.next(data);
    });
  }
 

  get(employeeId:number) {
    return this.http.get<Employee>(`${environment.apiUrl}/employee/${employeeId}`);
  }
  getList(type='firms') {
    return this.http.get<any>(`${environment.apiUrl}/employees/list/${type}`);
  }
  /**
   * should get the latest stats from the database once
   * Refresh should get latest content
   * If inbetween the loggedn in user or admin created an employee then the dashboard should reflect the same 
   *
   * */
  getStats(refresh = false) {
    if (this.authService.hasPermission("Manage Employee")) {
      if (refresh == true) {
        this.loadDashboardStats();
      }
    }
    return this._employeeDashboardStats.asObservable();
  }
  getDetail(employeeId: number) {
    return this.http.get<EmployeeDetail>(`${environment.apiUrl}/employee/${employeeId}/detail`);
  }


  saveDetail(detail: EmployeeDetail) {
    if (detail.id > 0) {
      return this.http.post<EmployeeDetail>(`${environment.apiUrl}/employee/detail/update`, detail);
    } else {
      return this.http.post<EmployeeDetail>(`${environment.apiUrl}/employee/detail/create`, detail);
    }
  }
  getSearchStatus() {
    return [{ 'name': 'Complete', 'id': '1' }, { 'name': 'Incomplete', 'id': '-1' },
      { 'name': 'Deleted', 'id': 'deleted' }
    ]
  }
  getUser(employeeId: number) {
    return this.http.get<User>(`${environment.apiUrl}/user/employee/${employeeId}`);
  }

  getContacts(employeeId: number) {
    return this.http.get<any>(`${environment.apiUrl}/contacts/employee/${employeeId}`);
  }

  create(employee: Employee) {
    return this.http.post<Employee>(`${environment.apiUrl}/employee/create`, employee);
  }

  update(employee: Employee) {
    return this.http.post<Employee>(`${environment.apiUrl}/employee/update`, employee);
  }
  updateAttribute(employeeId: number, attribute: string ,employee: Employee) {
    return this.http.post<Employee>(`${environment.apiUrl}/employee/${employeeId}/updateAttribute/${attribute}`, employee);
  }
  getEmployeeStatutory(employeeId: number) {
    return this.http.get<EmployeeStatutory>(`${environment.apiUrl}/employee/${employeeId}/statutory`);
  }
  saveEmployeeStatutory(employeeStatutory : EmployeeStatutory)
  {
    employeeStatutory.details = JSON.stringify(employeeStatutory.details);
    if (employeeStatutory.id && employeeStatutory.id > 0) {
      return this.http.post<EmployeeStatutory>(`${environment.apiUrl}/employee/${employeeStatutory.payroll_employees_id}/statutory/${employeeStatutory.id}`, employeeStatutory);
    } else {
      return this.http.post<EmployeeStatutory>(`${environment.apiUrl}/employee/${employeeStatutory.payroll_employees_id}/statutory`, employeeStatutory);
    }
  }
  deleteEmployeeStatutory(employeeStatutory : EmployeeStatutory)
  {
    if (employeeStatutory.id && employeeStatutory.id > 0) {
      return this.http.delete<any>(`${environment.apiUrl}/employee/${employeeStatutory.payroll_employees_id}/statutory/${employeeStatutory.id}`);
    }
  }

  resignEmployee(dor: string, id: number) {
    return this.http.post<any>(`${environment.apiUrl}/employee/resign/${id}`, { dor: dor });
    
  }
  revokeEmployee(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/employee/revoke/${id}`);

  } 
}
