import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HolidayComponent } from '../holiday.component';
import { Holiday } from '../../../_models/payroll/holiday';


@Component({
  selector: 'app-holiday-edit-dialog-box',
  templateUrl: './holiday-edit.component.html',
  styleUrls: ['./holiday-edit.component.css']
})
export class HolidayEditComponent  {

  action: string;
  local_data: any;
  weekDays = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
  
  constructor(

    public dialogRef: MatDialogRef<HolidayComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Holiday) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {

    console.log("in do action");
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
