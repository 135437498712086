import { User } from '../user';
import { Employee } from './employee';
export class EmployeeDetail{
  id: number = 0;;
    payroll_employees_id:number=0;
    father_name:string="";
    pancard:string="";
    adharcard_no:string="";
    dob:string="";
    identity_proof_image1:string|any;;
    identity_proof_image2:string|any;;
    email:string="";
    mobile:string="";
    address_1:string="";
    address_2: string = "";
    city_id: number;
    state_id: number;
    city_name: string = "";
    state_name: string = "";
    pincode: string = "";
    kys_info_complete:boolean=false;
    updated_by: User = new User();
    deleted_at:string;
    created_at:string;
    updated_at:string;
    is_mobile_verified?: boolean;
    is_email_verified?: boolean;
    primary_contact: any;
    landline?:string;
    profile_complete:any ;
}
