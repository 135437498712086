import { Component, OnInit, ViewChild } from '@angular/core';
import { AttendanceService } from '../_services';
import { Employee, Attendance } from '../_models/payroll';
import { EmployeeService } from '../_services/employee.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { EmployeewiseReportComponent } from './employeewise-report/employeewise-report.component';
import { AttendanceDayReportComponent } from './attendance-day-report/attendance-day-report.component';

@Component({
  selector: 'app-manage-attendance',
  templateUrl: './manage-attendance.component.html',
  styleUrls: ['./manage-attendance.component.css']
})
export class ManageAttendanceComponent implements OnInit {
  private employeewiseReport: EmployeewiseReportComponent;
  @ViewChild('employeewiseReport', { static: false, read: EmployeewiseReportComponent }) 
  set content(content: EmployeewiseReportComponent) {
    if (content) { // initially setter gets called with undefined
      this.employeewiseReport = content;
    }
  }
  private dayReportComponet: AttendanceDayReportComponent;
  @ViewChild('dayReportComponet', { static: false, read: AttendanceDayReportComponent }) 
  set content1(content1: AttendanceDayReportComponent) {
    if (content1) { // initially setter gets called with undefined
      this.dayReportComponet = content1;
    }
  }
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  today: NgbDate | null;
  activeMenu = "list";
  constructor(private attendanceService: AttendanceService, private employeeService: EmployeeService,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.today = calendar.getToday();
    console.log(this.today);
    this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 7);
  }
   
  employees = [];
  selectedEmployee: any;
  
  selectedType: any = "";
  type = [{"name": "Day Report", id: "day_report" }, { "name": "Employee Report", id: "employee_report" }]
  ngOnInit() {
    this.getEmployeeList();
    this.selectedType = "day_report";
  }

  getEmployeeList() {
    this.employeeService.getList('attendance-manager').subscribe(data => {
      this.employees = data;
    });
  }
  ngAfterViewInit() {
    this.refreshDashboard();
  }
  onSearch() {
    this.refreshDashboard();
  }
  onMenuItemSelect($event) {
    this.activeMenu = $event;
  }
  refreshDashboard() {
    switch (this.selectedType) {
      case "employee_report":
        this.employeewiseReport.search();
        break;
      case "day_report":
        this.dayReportComponet.showDayReport(this.fromDate);
        break;
    }
  }
 
  onFilterChange(filter, event) {
    if (filter == 'emp_id') {
      this.selectedEmployee = event;
    }
    if (filter == 'filter_type') {
      this.selectedType = event;
    }
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
 
}
