import { Component, OnInit, Directive } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthenticationService } from '../../../_services';
import { User } from '../../../_models';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

class MenuItems{
  icon:string;
  label:string;
  link:string;
  permissions:string[];
}

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.css']
})


export class WorkListComponent implements OnInit {

  currentUser: User;
  menu:MenuItems[]=[
    {icon:"dashboard",label:"Dashboard",link:"/home",permissions:['default']},
    {icon:"storefront",label:"Parties",link:"/parties",permissions:['parties']},
    {icon:"reduce_capacity",label:"Human Resources",link:"/hr",permissions:['Manage Employee']},
    {icon:"camera_enhance",label:"Take Attendance",link:"/attendence-register",permissions:['Take Attendance']},
    {icon:"input",label:"Attendance",link:"/attendance",permissions:['Manage Attendance']},
    {icon:"contact_phone",label:"Contacts",link:"/connection",permissions:['connection']},
    {icon:"settings",label:"Setup",link:"/setup",permissions:['Setup']},
  ];

  constructor(private _bottomSheetRef: MatBottomSheetRef<any>, private router: Router, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
  }
}
