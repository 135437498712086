export class User {
  id: number=0;
  name: string="";
  login_name: string="";
  api_token: string = "";
  employee_id: number = 0;
  work_mobile: string = "";
  work_email: string = "";
  updated_by: number = 0;
  deleted_at: string;
  token: string;
  token_type: string;
  expires_in: string;
  roles:any []=[];
  permissions:any []=[];
  is_super: boolean = false;
  authdata?: string;
  default_url?: string;
  password?: string;
  client_id: number;
  clients: any;
  manager_profile: any;
}
