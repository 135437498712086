import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AttendanceService } from '../../_services';
import { ShiftService } from '../../_services/payroll/shift.service';
import { AssociateShiftComponent } from '../shift/associate-shift/associate-shift.component';
import { DialogBoxComponent } from '../shift/dialog-box/dialog-box.component';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];
@Component({
  selector: 'app-attendance-payrun',
  templateUrl: './attendance-payrun.component.html',
  styleUrls: ['./attendance-payrun.component.css']
})
export class AttendancePayrunComponent implements OnInit {
  displayedColumns: string[] = ['name', 'date','detail' ,'action'];
  displayedColumnsAttendance: string[] = ['name', 'date', 'detail', 'in_time', 'out_time', 'action'];
  displayedColumnsReport: string[] = ['name', 'designation', "shiftname", 'total_days', 'late', 'late_15', 'late_30','early', 'early_15', 'early_30' ,'leaves' ,'absents','action'];
  dataSource = [];
  dataSourceShift = [];
  dataSourceAttendance = [];
  dataSourceReport = [];
  shifts: any;
  firstFormGroup: UntypedFormGroup;
  selectedMonth: number=0;
  selecetedYear: number = 0;
  loading = false;
  @ViewChild('stepper') private myStepper: MatStepper;

  constructor(private attendanceService: AttendanceService, public dialog: MatDialog, private shiftService: ShiftService, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      selectedMonth: ['', Validators.required]
    });
  }
  onDateSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.selectedMonth = event.value.getMonth();
    this.selecetedYear =event.value.getFullYear();
  }
  getData(stepper: MatStepper) {
    this.loading = true;
    this.shiftService.getAll().subscribe(data => {
      this.shifts = data;
    });

    this.attendanceService.getPreRunReport(this.selectedMonth + 1, this.selecetedYear).subscribe(data => {
      console.log(data);
      this.dataSourceShift = data.invalidShift;
      this.dataSourceAttendance = data.invalidAttendance;
      this.dataSourceReport = data.attanedance;
      this.loading = false;
      if (data) {
        if (data.invalidShift.length > 0) {
          stepper.next();
        } else if (data.invalidAttendance.length > 0) {
          stepper.next();
          stepper.next();
        } else {
          stepper.next();
          stepper.next();
          stepper.next();
        }
      }
    });
  }
  openDialog(action, obj) {
    if (action == 'AssociateShift') {
      obj.action = action;
      const dialogRef = this.dialog.open(AssociateShiftComponent, {
        data: obj,
      });
    } else {
      obj.action = action;
      const dialogRef = this.dialog.open(DialogBoxComponent, {
        data: obj,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
      });

    }
  }
  associateShift(event, element) {
    console.log(element)
    let i = this.dataSourceShift.findIndex(x => x.id == element.id && x.date == element.date);
    console.log(i);
    this.shiftService.attachToEmployee(element.id, event.value, element.date, element.date).subscribe(d => {
      this.dataSourceShift = this.dataSourceShift.filter(x => x !=element);

    }, error => {
      alert(error);
    });
  }
}
