import { Component, Input, Output, EventEmitter, ViewChild, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Party } from '../_models/party/party';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { PartyLicence } from '../_models/party/party-licence';
import { PartiesService } from '../_services/parties.service';
import { LicenceService } from '../../_services/Licence.service';

@Component({
  selector: 'app-party-licence',
  templateUrl: './party-licence.component.html',
  styleUrls: ['./party-licence.component.css']
})
export class PartyLicenceComponent implements OnInit  {
  @Input() party: Party = new Party();
  @Output() public submit: EventEmitter<Party> = new EventEmitter<Party>();
  @Output() cancel = new EventEmitter();

  @ViewChild(MatTable, { static: true }) table: MatTable<PartyLicence>;

  displayedColumns: string[] = ['main_category_id', 'license_number', 'expiry_date', 'created_at', 'action'];
  dataSource: any;

  constructor(public dialog: MatDialog,private partyService: PartiesService) {

  }

  ngOnInit() {
    console.log("Licence");
    this.getData();
  }
  openDialog(action, obj) {
    obj.action = action;
    obj.party_id = this.party.id;
    const dialogRef = this.dialog.open(PartyLicenceDialog, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update' || result.event == 'Renew') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  getData() {
    this.partyService.getPartyLicence(this.party.id).subscribe(data => {
      this.dataSource = data;
    });

  }
  addRowData(row_obj) {
    var d = new Date();
    let _local_data: PartyLicence = new PartyLicence();
    _local_data = Object.assign(_local_data, row_obj);
    this.dataSource.push(_local_data);
    this.table.renderRows();


  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value = Object.assign(value, row_obj);
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
  }

}

@Component({
  selector: 'party-licence-dialog',
  templateUrl: 'party-licence-dialog.html',
})
export class PartyLicenceDialog {

  action: string;
  local_data: any;
  categories:any[] = [];
  dataForm = this.fb.group({
    main_category_id: [null, Validators.required],
    license_number: [null, Validators.required],
    expiry_date: [null, Validators.required],
    document: null,
    competent_person_1: null,
    competent_person_2: null,
  });
  document_url = "";

  constructor(private fb: UntypedFormBuilder, private partyService: PartiesService,
    private licenceService: LicenceService,
    public dialogRef: MatDialogRef<PartyLicenceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PartyLicence) {
    this.populateMaster();
    this.local_data = { ...data };
    this.dataForm.patchValue(this.local_data);
    this.document_url = this.local_data.document;
    this.action = this.local_data.action;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    console.log(this.action);
    if (this.action == 'Add' || this.action == 'Update') {
      if (this.dataForm.invalid) {
        return;
      }
      this.saveData();
    } else if(this.action == 'Renew')
    {
      if (this.dataForm.invalid) {
        return;
      }
      this.renewData();
    }
    else if(this.action == 'Delete') {
      {
        console.log('in dleet');
        this.deleteData();
    }}
  }
  onRenew() {
    if (this.dataForm.invalid) {
      return;
    }
    this.renewData();
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  showPreview(event, ctrlName) {
    const file = (event.target as HTMLInputElement).files[0];
    this.dataForm.patchValue({
      ctrlName: file
    });
    this.dataForm.get(ctrlName).updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
          this.document_url = reader.result as string;
    this.dataForm.get(ctrlName).setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    reader.readAsDataURL(file)
  }
  get f() { return this.dataForm.controls; }
  prepareDataToSave() {
    let t = this.local_data;
    this.local_data = Object.assign(this.local_data, this.dataForm.value);
    this.local_data.id =t.id ? t.id:0;
    this.local_data.party_id = t.party_id;
    console.log(this.local_data);
  }
  populateMaster() {
    if (this.categories.length == 0) {
      this.licenceService.getCategories().subscribe(x => {
        this.categories = x;

      });
    }

  }
  saveData() {
    this.prepareDataToSave();
    this.partyService.savePartyLicence(this.local_data).subscribe(x => {
      this.local_data = x;
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
        alert(error);
    });
  }
  renewData() {
    this.prepareDataToSave();
    this.partyService.renewPartyLicence(this.local_data).subscribe(x => {
      this.local_data = x;
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
      alert(error);
    });
  }
  deleteData() {
    this.prepareDataToSave();
    this.partyService.deletePartyLicence(this.local_data).subscribe(x => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, error => {
      alert(error);
    });
  }
}
