import { User } from '../../../_models/user';

export class PartyLicence {
  id: number;
  party_id: number;
  main_category_id: number;
  license_number: string;
  expiry_date: string;
  document: string;
  competent_person_1: string;
  competent_person_2: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  party_maincategory:any;
  updated_by: User = new User();
  profile_complete:any ;
}
