import { Component, OnInit } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UntypedFormControl,FormGroup ,ReactiveFormsModule } from '@angular/forms';   
import { InfiniteScrollModule, InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BehaviorSubject, Observable } from 'rxjs';
import { first,debounceTime,distinctUntilChanged,switchMap } from 'rxjs/operators';
import { Connection,ConnectionEntities,ConnectionSearch,Location } from '@app/_models';
import { ConnectionService,LocationService } from '@app/_services';


@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css']
})
export class ConnectionComponent implements OnInit {

    loading = false;
    name_filter = "all";
    location_filter = "all";
    connections: Connection[]=[];
    connection_entities: ConnectionEntities[]=[];
    connection_entities_filters: any[]=[];
    locations:Location[]=[];
    connection_search: ConnectionSearch = new ConnectionSearch();
    total= 0;
    current_page=1;
    current_count:0;

    selectedLocation: any;
    selectedFilter: any=[];
    results: any[] = [];
    namequeryField: UntypedFormControl = new UntypedFormControl();
    //locationqueryField: FormControl = new FormControl();
    
    constructor(private connectionService: ConnectionService,private locationService: LocationService,private config: NgSelectConfig) { 
        this.config.notFoundText = 'Custom not found';
    }

    ngOnInit() {
       this.loading = true;
       this.getResults();
        this.getEntities();
        this.getLocations();
        this.getEntityFilters(-1);
        this.namequeryField.valueChanges
            .subscribe(val=>{
            this.name_filter=val
            this.getResults()});
        
    }
    locationChanged(event)
    {
        
        this.location_filter = event == null?"all":event;
        this.getResults();
    }
    entityChanged(id)
    {
      this.getEntityFilters(id);
      this.connection_search.model_type = id == -1?"":id;
      this.ResetFilter();
      this.getResults();
    }
    
    filterChanged(name,id)
    {
        console.log(this.selectedFilter);
        let groups= this.connection_search.filters.filter(x=>x.name == name);
        //console.log(groups);
        if(groups.length>0)
        {
            groups[0].val = id;
        }else
        {
            this.connection_search.filters.push({"name":name,"val":id});
        }
        //console.log(selectedFilter);
        this.getResults();
    }
    ResetFilter()
    {
        this.selectedFilter=[];
        this.connection_search.filters =[];
    }
    pageChanged()
    {
        this.current_page = this.current_page + 1;
        this.loadMore();
        
    }
    
    getEntities()
    {
        this.connectionService.getEntities()
        .subscribe(entities => {
        //console.log(entities);
        this.loading = false;
        this.connection_entities = entities;});
        
    }
    getEntityFilters(entity_id)
    {
        this.connectionService.getEntityFilters(entity_id)
        .subscribe(filters => {
            console.log(filters);
            this.loading = false;
            this.connection_entities_filters =filters;
        });
    }
    pageChangedup()
    {
        console.log("i am going up");
    }
    getLocations()
    {
        this.locationService.getAll()
        .subscribe(entities => {
        //console.log(entities);
        this.loading = false;
        this.locations = entities;});
    }
    getResults()
    {
        this.current_page = 1;
        this.connectionService.search(this.name_filter,this.location_filter,this.current_page,this.connection_search)
        .subscribe(connections => {
                        this.loading = false;
                        this.total =  connections.total || 0;   
                        this.current_count = connections.to;
                        //this.current_page =  connections.current_page;   
                         this.connections =  connections.data || {};
            });
    }

    loadMore()
    {
      console.log("called");
        this.connectionService.search(this.name_filter,this.location_filter,this.current_page,this.connection_search)
        .subscribe(connections => {
                        this.loading = false;
                        this.total =  connections.total || 0;   
                        this.current_count = connections.to;
                        //this.current_page =  connections.current_page;   
                         connections.data.forEach(obj=>{
                            this.connections.push(obj);
                        } ); 
    });}

  getIconClass(model_type) {
    if (model_type.includes("Employee")) {
      return "fa fa-user mr-2";
    }else if(model_type.includes("Party")) {
      return "fa fa-handshake mr-2";
    }
    return "mr-2";
  }
                  
}
