import { Component,  Input, Output, EventEmitter, Inject } from '@angular/core';
import { Employee } from '@app/_models/payroll';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { EmployeeService } from '@app/_services/employee.service';
import { empty } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  @Input('employee') employee: Employee;
  @Input('user') user :  User;
  @Output() public submit: EventEmitter<User> = new EventEmitter<User>();
  @Output() cancel = new EventEmitter();
  submitted:boolean;
  loading:boolean;
  error:string = '';
  password:string = '';
  userId: any;

  changePasswordForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirm_password: ['', [Validators.required]],
  });

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService, private employeeService: EmployeeService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.userId = data;
    console.log(this.userId);
  }

  get f() { return this.changePasswordForm.controls; }

  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    if(this.changePasswordForm.invalid){
      this.loading = false;
      return;
    }
    let _user: User = new User();
    _user.id = this.userId;
    _user.password = this.changePasswordForm.controls.password.value;
    this.userService.changePassword(this.userId,_user).subscribe(x => {
      this.submitted = false;
      this.loading = false;
      this.dialogRef.close("changed");
      this.submit.emit(x);
      
    },
    error => {
      this.error = error;
      this.submitted = false;
      this.loading = false;
    })
  }
  onCancel() {
    this.cancel.emit();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.cancel.emit();
  }
}
