import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { User, Module } from '../../../_models';
import { EmployeeService } from '../../../_services/employee.service';
import { UserService } from '../../../_services/user.service';
import { RoleService } from '../../../_services/role.service';
import { Employee } from '../../../_models/payroll';
import { ModuleService, ConfirmPasswordValidator,  } from '../../../_services';
import { ClientService } from '@app/_services/client.service';
import { FirmService } from '../../../_services/payroll/firm.service';
import { Observable } from 'rxjs';
import { Firm } from '../../../_models/payroll/firm';
import { Client } from '../../../_models/client';

@Component({
  selector: 'app-add-portal-user',
  templateUrl: './add-portal-user.component.html',
  styleUrls: ['./add-portal-user.component.css']
})
export class AddPortalUserComponent implements OnInit {
  @Input('user') user: User = new User();
  @Input('associatedEmployee') associatedEmployee: Employee;
  @Input('cancelText') btnCancelText: string ="Cancel";
  @Input('submitText') btnSubmitText: string="Submit";

  @Output() public submit: EventEmitter<User> = new EventEmitter<User>();
  @Output() cancel = new EventEmitter();

  submitted: boolean = false;
  loading: boolean = false;
  error = '';
  employees: any = [];
  roles: any = [];
  selectedPermissions: any = [];
  selectedRoles: any = [];
  permissionIdError = "";
  
  selectedClients: any = [];
  selectedFirms: any = [];
  selectedAttendanceManager: any = [];
  action: string = '';
  firms$: Observable<Firm[]>;
  modules$: Observable<Module[]>;
  clients$: Observable<Client[]>;
  attendanceManager: any = [];

  canManageEmployee = false;
  canManageAttendance = false;

  editUserForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    login_name: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9_]+$")]],
    work_email: [null, [Validators.email]],
    work_mobile: [null],
    employee_id: [null],
    roleIds: [null],
    permissionIds: this.formBuilder.array([]),
    client_id: [],
    manager_firm_id: [],
    attendance_manager_id: [],
  });
  constructor(private config: NgSelectConfig, private formBuilder: UntypedFormBuilder, private employeeService: EmployeeService,
    private userService: UserService,
    private roleService: RoleService,
    private moduleService: ModuleService,
    private clientService: ClientService,
  private firmService:FirmService) {
  }

  ngOnInit() {
    this.getEmployees();
    this.populateMaster();
    this.initForm();
    
  }

  ngOnChanges(changes: User) {
    
  }
  onSelectRole(event) {
    this.selectedRoles = [];
    this.selectedPermissions = [];
    event.forEach(x => {
      this.selectedRoles.push(x.id);
      if (x.permissions && x.permissions.length > 0) {
        x.permissions.forEach(y => {
          //this.selectedPermissions.push(y.id);
          this.setPermission(y.id, y.name);
        });
      }
    });
  }
  onSelectClient(event) {
    this.selectedClients = [];
    event.forEach(element => {
      console.log(element);
      this.selectedClients.push(element.id);
    });
  }

  onSelectFirm(event) {
    this.selectedFirms = [];
    event.forEach(element => {
      this.selectedFirms.push(element.id);
    });

  }
  onSelectAttendanceManager(event) {
    this.selectedAttendanceManager = [];
    event.forEach(element => {
      this.selectedAttendanceManager.push(element.id);
    });

  }
  onSelectPermission(event, pname) {
    
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.setPermission(event.target.value, pname);

    }
    /* unselected */
    else {
      this.unsetPermission(event.target.value, pname);
    }
  }
  unsetPermission(id, pname) {
    this.selectedPermissions = this.selectedPermissions.filter(h => h !== id);
    switch (pname) {
      case "Manage Attendance":
        this.canManageAttendance = false;
        break;
      case "Manage Employee":
        this.canManageEmployee = false;
        break;
    }
  }

  setPermission(id,pname) {
    this.selectedPermissions.push(id);
    
    switch (pname) {
      case "Manage Attendance":
        this.canManageAttendance = true;
        break;
      case "Manage Employee":
        this.canManageEmployee = true;
        break;
    }
  }
  onCancel() {
    this.cancel.emit();
  }
  onSubmit() {
    this.submitted = true;
    
    if (this.editUserForm.invalid) {
      return;
    }
    if (this.selectedPermissions.length < 1) {
      this.permissionIdError = "You should select atleast one Permission";
      return;
    }
    this.loading = true;
    this.permissionIdError = "";

    let _user: User = this.user.id > 0 ? this.user : new User();
    _user = Object.assign(_user, this.editUserForm.value);
    if (!this.isEditMode()) {
      _user.password = this.f.password.value;
    }
    _user.roles = this.selectedRoles;
    _user.permissions = this.selectedPermissions;
    _user.client_id = this.selectedClients;
    _user.manager_profile = { "firms": this.selectedFirms, "attendance_logins": this.selectedAttendanceManager };
    
    if (this.user.id >0 ) {
      this.userService.update(_user).subscribe(x => {
        this.submitted = false;
        this.loading = false;
        this.submit.emit(x);
      },
      error => {
        this.error = error;
        this.submitted = false;
        this.loading = false;
      })
    } else {
      this.userService.create(_user).subscribe(x => {
        this.submitted = false;
        this.loading = false;
        this.submit.emit(x);
      },
      error => {
        this.error = error;
        this.submitted = false;
        this.loading = false;
      })
    }
   
  }
  getUser() {
    if (this.user && this.user.id > 0) {
      this.userService.getUser(this.user.id).subscribe(resp => {
        this.user = resp;
        this.initForm();
      },
        err => {
          this.user = new User();
        });
    } else {
      this.user = new User();
      this.initForm();
    }
    
  }
  getEmployees() {
    this.employeeService.getList().subscribe(resp => {
      this.employees = resp;

    });
  }


  addPermissionControlsToForm(resp) {
    const parry = this.editUserForm.get('permissionIds') as UntypedFormArray
    if (resp) {
      resp.forEach(x => {
        x.permissions.forEach(y => {
          parry.push(this.createPermissionFormGroup());
        });
      });
    }
  }
  

  populateMaster() {
    this.firms$ = this.firmService.firms;
    this.modules$ = this.moduleService.modules;
    this.clients$ = this.clientService.clients;

    this.userService.getAttendanceRegisterars().subscribe(users => {
      this.attendanceManager = users;
    })
    this.roleService.getAll().subscribe(resp => {
      this.roles = resp;
    });
    this.modules$.subscribe(x => {
      this.addPermissionControlsToForm(x);
    });
  }
  initForm() {
    console.log(this.user);
    if (this.user.permissions)
      this.user.permissions.forEach(x => {
        this.setPermission(JSON.stringify(x.id), x.name);
    });
    if (this.user.roles)
    this.user.roles.forEach(x => {
      this.selectedRoles.push(x.id);
    });
    if(this.user.clients)
    this.user.clients.forEach(x => {
      this.selectedClients.push(x.id);
    });
    if (this.associatedEmployee) {
      this.user.employee_id = this.associatedEmployee.id;
      this.user.name = this.associatedEmployee.first_name + " " + this.associatedEmployee.last_name;
      this.user.work_email = this.associatedEmployee.work_email;
    }
    if (this.associatedEmployee) {
      this.user.name = this.associatedEmployee.first_name;
    }
    if (this.user.manager_profile  &&  this.user.manager_profile != "null") {
      let profile = JSON.parse(this.user.manager_profile);
      if (profile.firms) {
        this.selectedFirms = profile.firms;
      }
      if (profile.attendance_logins) {
        this.selectedAttendanceManager = profile.attendance_logins;
      }
     
    }
    this.editUserForm.patchValue(this.user);
    if (!this.isEditMode()) {
      this.editUserForm.addControl('password', new UntypedFormControl('', [Validators.required, Validators.minLength(6)]));
      this.editUserForm.addControl('confirm_password', new UntypedFormControl('', [Validators.required]));
    }
    
  }
  private createPermissionFormGroup(val = ''): UntypedFormGroup {
    return new UntypedFormGroup({
      'permissionId': new UntypedFormControl(val),
    })
  }
  get f() { return this.editUserForm.controls; }
  // custom validator to check that two fields match

   mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
   }

  hasRole(id) {
    let r = this.selectedRoles.find(x => x === id);
    
    if (r)
      return true;
    else
      return false;
  }

  hasPermission(id) {
    let r = this.selectedPermissions.find(x => x == id);
    if (r) {
      return true;
    }
    else
      return false;
  }

  isEditMode() {
      return this.user && this.user.id >0
  }
  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }
 }
