import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Department } from '../../_models/payroll/department';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepartmentService {
  private _departments: BehaviorSubject<Department[]> = new BehaviorSubject<Department[]>([]);
  private dataStore: { departments: Department[] } = { departments: [] };
  //readonly departments = this._departments.asObservable();

  constructor(private http: HttpClient) {
    this.loadAll();
  }
  get departments() {
    return this._departments.asObservable();
  }
  loadAll() {
    return this.http.get<Department[]>(`${environment.apiUrl}/payroll/setup/departments`).subscribe(data => {
      this.dataStore.departments = data;
      this._departments.next(Object.assign({}, this.dataStore).departments);
    },
      error => console.log('Could not load departments.'));
  }
 
}
