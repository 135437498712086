import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_helpers';
import { HolidayComponent } from './holiday/holiday.component';
import { ShiftComponent } from './shift/shift.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { AttendancePayrunComponent } from './attendance-payrun/attendance-payrun.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManageAttendanceComponent } from './manage-attendance.component';




const routes: Routes = [
  {
    path: '',
    component: ManageAttendanceComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Manage Attendance"] },
  children: [
    {
      path: '',
      component: DashboardComponent,
      canActivate: [AuthGuard],
      data: { permissions: ["Manage Attendance"] }
    },
  {
    path: 'holidays',
    component: HolidayComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Manage Attendance"] }
  },
  {
    path: 'shifts',
    component: ShiftComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Manage Attendance"] }
  },
  {
    path: 'approvals',
    component: PendingApprovalComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Manage Attendance"] }
  },
  {
    path: 'payrun',
    component: AttendancePayrunComponent,
    canActivate: [AuthGuard],
    data: { permissions: ["Manage Attendance"] }
  }]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageAttendanceRoutingModule { }
