import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }   

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /*public get currentUserSettings() {
        if(this.currentUserValue && this.currentUserValue.user && this.currentUserValue.user.settings)
        {
            return this.currentUserValue.user.settings;
        }
        return null;
    }*/
  login(login_name: string, password: string) {
    return this.http.post<User>(`${environment.apiUrl}/login`, { login_name, password })
      .pipe(map(user => {
        if (user && user.token) {
          // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
          //user.authdata = window.btoa(email + ':' + password);
          //console.log(user);
          localStorage.setItem('currentUser', JSON.stringify(user));
          //console.log(JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
       }));
    }
    
    loginbyToken()
    {
      return this.http.get<User>(`${environment.apiUrl}/users/authenticate/` + this.currentUser.subscribe(x => {
        return x.api_token;
      }))
        .pipe(map(user => {
            // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
            //user.authdata = window.btoa(email + ':' + password);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
        
    }
    
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
