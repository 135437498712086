import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Role } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class RoleService {
  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<Role[]>(`${environment.apiUrl}/setup/roles`);
  }
  create(role: Role) {
        let obj: any;
        obj = role;
        obj.permissions = JSON.stringify(role.permissions);
        return this.http.post<Role>(`${environment.apiUrl}/setup/role`, obj);
  }
  delete(id: number) {
      return this.http.delete(`${environment.apiUrl}/role/${id}`);
  }
  
}
