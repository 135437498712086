import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicenceService {
  constructor(private http: HttpClient) { }
  getCategories() {
    return this.http.get<any>(`${environment.apiUrl}/licence/categories/list`);
  }
}
