import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Shift } from '../../_models/payroll/shift';



@Injectable({ providedIn: 'root' })
export class ShiftService {
  constructor(private http: HttpClient) { }

  create(shift: Shift) {   
    if (shift.id && shift.id > 0) {   
     return this.http.put<Shift>(`${environment.apiUrl}/payroll/shifts/${shift.id}`, shift);
    } else {    
     return this.http.post<Shift>(`${environment.apiUrl}/payroll/shifts`, shift);
    }
  }

  getAll() {
    return this.http.get<Shift>(`${environment.apiUrl}/payroll/shifts`);
  }

  deleteShift(shift: Shift)
  {
    if (shift.id && shift.id > 0) {
      console.log(shift.id);
      return this.http.delete<any>(`${environment.apiUrl}/payroll/shifts/${shift.id}`);
    }
  }
  dettachFromEmployee(employeeId, shiftId, startDate, endDate) {
    return this.http.post<any>(`${environment.apiUrl}/payroll/employees/${employeeId}/shifts/${shiftId}/remove`, { start_date: startDate, end_date: endDate });
  }
  attachToEmployee(employeeId, shiftId, startDate, endDate) {
    return this.http.post<any>(`${environment.apiUrl}/payroll/employees/${employeeId}/shifts/${shiftId}`, { start_date: startDate, end_date: endDate });
  }
}
