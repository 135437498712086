import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Client } from '@app/_models/client';
import { PartyGroups } from '@app/parties/_models/party/party-groups';
import { PartyClients } from '@app/parties/_models/party/party-clients';
import { ClientEmployee } from './client/client-employee';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientService {
  private _clients: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);
  private dataStore: { clients: Client[] } = { clients: [] };

  constructor(private http: HttpClient) {
    this.loadAll();
  }

  get clients() {
    return this._clients.asObservable();
  }

  loadAll() {
    return this.http.get<Client[]>(`${environment.apiUrl}/payroll/setup/clients`).subscribe(data => {
      this.dataStore.clients = data;
      this._clients.next(Object.assign({}, this.dataStore).clients);
    },
      error => console.log('Could not load firms.'));
  }
  getPartyClient() {
    return this.http.get<PartyClients[]>(`${environment.apiUrl}/clients`)
  }
  getClientEmployees(clientId: number) {
    return this.http.get<ClientEmployee[]>(`${environment.apiUrl}/client/${clientId}/employees`)
  }

}
