import { Component, ViewChild  } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { AssociateShiftComponent } from './associate-shift/associate-shift.component';
import { ShiftService } from '../../_services/payroll/shift.service';



export interface UsersData {
  name: string;
  id: number;
}


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent {
  displayedColumns: string[] = ['id', 'name', 'weekly_off', 'in_time', 'out_time', 'min_present_hours', 'dinner_allowance_time', 'breakfast_allownace_time', 'is_night_shift','min_overtime_hours', 'action'];
  dataSource: any;
  
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(public dialog: MatDialog,
    private shiftService: ShiftService
  ) {
    this.getData();
  }

  openDialog(action, obj) {
    if (action == 'AssociateShift' || action == 'DissociateShift'){
      obj.action = action;
      const dialogRef = this.dialog.open(AssociateShiftComponent, {
        data: obj,
      });
    }else
    {
      obj.action = action;
      const dialogRef = this.dialog.open(DialogBoxComponent, {
        data: obj,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result.event == 'Add') {
          this.addRowData(result.data);
        } else if (result.event == 'Update') {
          this.updateRowData(result.data);
        } else if (result.event == 'Delete') {
          this.deleteRowData(result.data);
        }
      });

    }
  }

  getData() {
    this.shiftService.getAll().subscribe(data => {
      this.dataSource = data;
    });
  }
  addRowData(row_obj) {
    var d = new Date();
    this.dataSource.push(row_obj);
    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value = Object.assign(value, row_obj);
      }
      return true;
    });
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
  }
  
}
