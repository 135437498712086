import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
          if (route.data.permissions) {
            if (!this.checkPermission(currentUser, route.data.permissions)) {
              // role not authorised so redirect to home page
              this.router.navigate(['/']);
              return false;
            }
          }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
  }
  private checkPermission(currentUser,routePermissions) {

    let hasPermission = false;
    if (currentUser && currentUser.is_super === true) {
      return true;
    }

    if (currentUser && currentUser.permissions) {
      
      for (const checkPermissions of routePermissions) {
        const permissionFound = currentUser.permissions.find(x => x.toUpperCase() === checkPermissions.toUpperCase());
        if (permissionFound) {
          hasPermission = true;
          break;
          //console.log(this.currentUser);
        } else {
          hasPermission = false;
        }
      }
    }
    return hasPermission;
  }
    
}
