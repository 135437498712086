import { Component, ViewChild  } from '@angular/core';
//import { MatTable } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MonthlySalaryReportService } from '../../_services/payroll/monthly-salary-report.service';
import { FormsModule, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
//import {MatInputModule} from '@angular/material/input';
import { jsPDF} from 'jspdf';
import  html2canvas  from 'html2canvas';
//import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-monthly-salary-report',
  templateUrl: './monthly-salary-report.component.html',
  styleUrls: ['./monthly-salary-report.component.css']
})
export class MonthlySalaryReportComponent { 
  dataSource: any;
  report_month=[
    {
      id: '1',
      name: 'JAN'
    },
    {
      id: '2',
      name: 'FEB'
    },
    {
      id: '3',
      name: 'MAR'
    },
    {
      id: '41',
      name: 'APR'
    },
    {
      id: '5',
      name: 'MAY'
    },
    {
      id: '6',
      name: 'JUN'
    },
    {
      id: '7',
      name: 'JUL'
    },
    {
      id: '8',
      name: 'AUG'
    },
    {
      id: '9',
      name: 'SEPT'
    },
    {
      id: '10',
      name: 'OCT'
    },
    {
      id: '11',
      name: 'NOV'
    },
    {
      id: '12',
      name: 'DEC'
    }
  ]; 
  current_year:Number;

  dataForm:UntypedFormGroup= this.fb.group({      
    r_month: ['',Validators.required]    
    });

  constructor(private monthlySalaryReportService: MonthlySalaryReportService, private fb: UntypedFormBuilder) {    
    //this.getData(5, 2021);  
  }
  
  ngOnInit() {
    this.getData(5, 2021);  
  }


  getData(r_month:Number, r_year:Number) {
    this.monthlySalaryReportService.getReport(r_month,r_year).subscribe(data => {
      if(data){
        console.log("got data");
        this.dataSource = data;      
      }
      
    });
  } 
  
  
   /* 
   
    downloadPDF() {    
      const data = document.getElementById('PayrollMonthlyReport');     
      console.log(data);
      const options = { background: 'white', height: 845, width: 595 };
      domtoimage.toPng(data, options).then(dataUrl => {
          //Initialize JSPDF
          const doc = new jsPDF('p', 'mm', 'a4');
          //Add image Url to PDF
          doc.addImage(dataUrl, 'PNG', 0, 0, 210, 340);
          doc.save('PayrollMonthlyReport.pdf');
      });
      }
*/
      
      downloadPDFMultiple() {        
        var data = document.getElementById('PayrollMonthlyReport');
      // console.log(data);
        html2canvas(data).then(canvas => {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 550; 
        var pageHeight = 295;  
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm', 'a4');
        var position = 10; // give some top padding to first page

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( 'PayrollMonthlyReport.pdf');
        });
      }
}
