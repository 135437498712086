import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Location } from '@app/_models';


@Injectable({ providedIn: 'root' })
export class LocationService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Location[]>(`${environment.apiUrl}/location/cities`);
      
    }

  getStates() {
    return this.http.get<Location[]>(`${environment.apiUrl}/location/states`);
  }

  getCities(stateId: number)
  {
    return this.http.get<Location[]>(`${environment.apiUrl}/location/state/${stateId}/cities`);
  }
   
    
}
