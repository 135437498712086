import { Routes, RouterModule,PreloadAllModules } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ConnectionComponent } from './connection';
import { ExpensesComponent } from './expenses';
import { TennotificationComponent } from './tennotification';
import { HrComponent } from './hr';
import { PayrollComponent } from './payroll';
import { AttendenceComponent } from './attendence';
import { AuthGuard } from './_helpers';
import { ClientsComponent } from './clients/clients.component';
import { PartiesComponent } from './parties/parties.component';





const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'connection', component: ConnectionComponent, canActivate: [AuthGuard] },
    { path: 'expenses', component: ExpensesComponent, canActivate: [AuthGuard] },
    { path: 'tennotification', component: TennotificationComponent, canActivate: [AuthGuard] },
    { path: 'hr', component: HrComponent, canActivate: [AuthGuard], data: { permissions: ["Employee"] } },
    { path: 'client', component: ClientsComponent, canActivate: [AuthGuard], data: { permissions: ["Client"] } },
    { path: 'payroll', component: PayrollComponent, canActivate: [AuthGuard] },
    { path: 'attendence-register', component: AttendenceComponent, canActivate: [AuthGuard], data: { permissions: ["Take Attendance"] } },
    //{ path: 'holiday', component: HolidayComponent, canActivate: [AuthGuard], data: { permissions: ["Attendance"] } },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    {
      path: 'my-profile',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      canActivate: [AuthGuard]
  },
  {
    path: 'my-attendance',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
    {
      path: 'attendance',
      loadChildren: () => import('./manage-attendance/manage-attendance.module').then(m => m.ManageAttendanceModule),
      canActivate: [AuthGuard],
      data: { permissions: ["Manage Attendance"] }
    },
    {
      path: 'setup',
      loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule),
      canActivate: [AuthGuard]
    },
    { path: 'parties',
    loadChildren: () => import('./parties/parties.module').then(m => m.PartiesModule),
    canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '/home' },
];

export const appRoutingModule = RouterModule.forRoot(routes ,{ preloadingStrategy: PreloadAllModules });
