import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../_helpers';
import { ProfileComponent } from './profile.component';
import { MyAttendanceReportComponent } from './my-attendance-report/my-attendance-report.component';



const routes: Routes = [
  {
    path: 'my-profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-attendance',
    component: MyAttendanceReportComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
