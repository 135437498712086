import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgSelectConfig} from '@ng-select/ng-select';
import { FormControl,UntypedFormGroup ,ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms'; 
import { EmployeeService } from '@app/_services/employee.service';
import { Employee, Department, Designation } from '@app/_models/payroll';
import { DepartmentService } from '../../_services/payroll/department.service';
import { DesignationService } from '../../_services/payroll/designation.service ';
import { UserService } from '@app/_services';
import { FirmService } from '@app/_services/payroll/firm.service';
import { formatDate } from '@angular/common';
import { NgbDate, NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';  
import { Observable } from 'rxjs';
import { Firm } from '../../_models/payroll/firm';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css']
})
export class EmployeeEditComponent implements OnInit {
  @Input('employee') employee: Employee = new Employee();
  @Output() public submitSuccess: EventEmitter<Employee> = new EventEmitter<Employee>();
  @Output() cancel = new EventEmitter();
  submitted :boolean=false;
  loading:boolean = false;
  editEmployeeForm : UntypedFormGroup;
  error = '';
  profile_image_url = '';
  today: any = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };//
  dept : number;
  isEditMode = false;
  reportingManager: any = [];
  attendanceManager: any = [];
  firms: Observable<Firm[]>;
  departments: Observable<Department[]>;
  designations: Observable<Designation[]>;

  btnSubmitText: string = "Submit";
  constructor(private employeeService: EmployeeService,
    private departmentService: DepartmentService,
    private designationService: DesignationService,
    private userservice: UserService,
    private firmSerivce: FirmService,
    private config: NgSelectConfig, private formBuilder: UntypedFormBuilder) {
    this.config.notFoundText = 'Custom not found';
   }

  ngOnChange(changes: Employee)
  {
    
    this.profile_image_url =  this.employee.profile_image;
  }
  ngOnInit() {
    if (this.employee && this.employee.id == 0) {
      this.isEditMode = false;
      this.btnSubmitText = "Next";
    } else {
      this.isEditMode = true;
    }
    this.populateMasters();
    
    this.error = '';
    this.profile_image_url =  this.employee.profile_image;
    let _doj: NgbDate;
    if (this.employee.doj) {
      let _dobdt = new Date(this.employee.doj);
      _doj = new NgbDate(_dobdt.getFullYear(), _dobdt.getMonth() + 1, _dobdt.getDate());
    }
    this.editEmployeeForm = this.formBuilder.group({
      first_name: [this.employee.first_name, [Validators.required]],
      last_name: [this.employee.last_name],
      middle_name: [this.employee.middle_name],
      // work_email: [this.employee.work_email, [Validators.email]],
      gender: [this.employee.gender, [Validators.required]],
      doj: [_doj, [Validators.required]],
      profile_image: [this.employee.profile_image, [Validators.required]],
      department_id: [this.employee.department_id, [Validators.required]],
      designation_id: [this.employee.designation_id, [Validators.required]],
      reporting_manager_id: [this.employee.reporting_manager_id],
      attendance_manager_id: [this.employee.attendance_manager_id, [Validators.required]],
      firm_id: [this.employee.firm_id, [Validators.required]],
    });
    this.getAttendanceManager();
    
  }
  onCancel() {
    this.cancel.emit();
  }
  onSaveClick(){
    this.submitted = true;
   

    if (this.editEmployeeForm.invalid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    let _employee: Employee = new Employee();
    _employee.id = this.employee.id;
    _employee.first_name = this.f.first_name.value;
    _employee.middle_name = this.f.middle_name.value;
    _employee.last_name = this.f.last_name.value;
    _employee.gender = this.f.gender.value;
    // _employee.work_email = this.f.work_email.value;
    _employee.profile_image = this.employee.profile_image;//this.f.profile_image.value;
    _employee.department_id = this.f.department_id.value;
    _employee.designation_id = this.f.designation_id.value;
    _employee.reporting_manager_id = this.f.reporting_manager_id.value;
    _employee.attendance_manager_id = this.f.attendance_manager_id.value;
    _employee.firm_id = this.f.firm_id.value;
    _employee.doj = formatDate(new Date(this.f.doj.value.year, this.f.doj.value.month - 1, this.f.doj.value.day).toDateString(), "y-MM-dd", 'en-US');
    if(this.employee.id)
    {
      this.employeeService.update(_employee).subscribe(x => {
        this.submitted = false;
        this.loading = false;
        this.submitSuccess.emit(x);
      },
        error => {
          this.error = error;
          this.submitted = false;
          this.loading = false;
        })
    }else{
      this.employeeService.create(_employee).subscribe(x => {
        this.submitted = false;
        this.loading = false;
        this.submitSuccess.emit(x);
      },
        error => {
          this.error = error;
          this.submitted = false;
          this.loading = false;
        })
    }
    
  
  }
  onEditProfileImageSuccess(x) {
    this.editEmployeeForm.get('profile_image').setValue(x);
    this.editEmployeeForm.get('profile_image').updateValueAndValidity();
    console.log(x);
  }
  onEditProfileImageCancel(x: any) {
    console.log(x);
  }
  // Image Preview
  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.editEmployeeForm.patchValue({
      profile_image: file
    });
    this.editEmployeeForm.get('profile_image').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.profile_image_url = reader.result as string;
      this.editEmployeeForm.get('profile_image').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result
      })
    }
    
    reader.readAsDataURL(file)
  }
  // convenience getter for easy access to form fields
  get f() { return this.editEmployeeForm.controls; }


  getAttendanceManager(){
    this.userservice.getAll().subscribe(users => {
      
      this.reportingManager = users;
    })
    this.userservice.getAttendanceRegisterars().subscribe(users => {
      this.attendanceManager = users;
    })
    
  }
  populateMasters() {
    this.firms = this.firmSerivce.firms;
    this.departments = this.departmentService.departments;
    this.designations = this.designationService.designations;
  }

}
