import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../_services';
import { User } from '../../../_models';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkListComponent } from '../work-list/work-list.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentUser: User;
  constructor(private router: Router, private authenticationService: AuthenticationService, private worklist: MatBottomSheet) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }
  openWorkMenu(): void {
    this.worklist.open(WorkListComponent);
  }
}
