import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map, catchError, finalize } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { Holiday } from '../../_models/payroll/holiday';
import { HolidayService } from '../../_services/payroll/holiday.service';

/**
 * Data source for the Holiday view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class HolidayDataSource implements DataSource<Holiday> {

  private holidaySubject = new BehaviorSubject<Holiday[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
  paginator: MatPaginator;
  sort: MatSort;

  constructor(private holidayService: HolidayService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Holiday[]> {
    return this.holidaySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.holidaySubject.complete();
    this.countSubject.complete();
    this.loadingSubject.complete();
  }

  loadHolidays(filter = '', sortOrder = 'asc', pageNumber = 0, pageSize = 3) {
    this.loadingSubject.next(true);
    this.holidayService.findHolidays(filter, sortOrder, pageNumber, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {
      this.holidaySubject.next(response.data)
      this.countSubject.next(response.total)
    });
  }
  
}
