import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatutoryService {
  constructor(private http: HttpClient) { }
  getStatutory() {
    return this.http.get<any>(`${environment.apiUrl}/statutory/list`);
  }
}