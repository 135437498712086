import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Client } from '@app/_models/client';
import { ClientService } from '@app/_services/client.service';
import { LocationService } from '@app/_services';
import { PartiesService } from '@app/parties/_services/parties.service';
import { Party } from '@app/parties/_models/party/party';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { search } from '../_models/party/search';

@Component({
  selector: 'app-party-search',
  templateUrl: './party-search.component.html',
  styleUrls: ['./party-search.component.css']
})
export class PartySearchComponent {
  action:string = '';
  selectedParty: Party;

  labelqueryField: UntypedFormControl = new UntypedFormControl();
  thirdpartycodequeryField: UntypedFormControl = new UntypedFormControl();
  citiesqueryField: UntypedFormControl = new UntypedFormControl();
  clients$: Observable<Client[]>;
  states: any[]= [];
  cities: any[]= [];
  params:search={label:'',city:'',state:'',client:'',status:'',thirdPartyCode:''};
  total = 0;
  current_page = 1;
  current_count: 0;
  isMobile: any;;
  loading = false;
  parties : Party[];
  status: any[]=[];

  constructor(private partyService: PartiesService, private clientService: ClientService, private locationService: LocationService) { }

  ngOnInit() {
  if (!this.isMobile) {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.populateSearchDropdown();
    }, 1);

  }

  this.labelqueryField.valueChanges
    .subscribe(val => {
      this.params.label=val;
      this.current_page=1;
      this.getResults()
    });
  this.thirdpartycodequeryField.valueChanges
    .subscribe(val => {
      this.params.thirdPartyCode = val;
      this.current_page=1;
      this.getResults()
    });
  this.citiesqueryField.valueChanges
    .subscribe(val => {
      this.params.city = val;
      this.current_page=1;
      this.getResults()
    });
  }
  applyFilter($name, $event) {
    if ($event && $event.value) {
      this.params[$name] = $event.value;
    } else {
      delete this.params[$name];
    }
    this.current_page = 1;
    this.action = '';
    this.getResults();
  }

  getResults() {
    let refresh: boolean = true;
   this.partyService.search(this.params, this.current_page).subscribe(x => {
      console.log(x);
      this.loading = false;
      this.total = x.total || 0;
      this.current_count = x.to;
      if (x.data) {
        if (refresh === true) {
          this.parties = [];
        }
        x.data.forEach(obj => {
          this.parties.push(obj);
        });
      }
    })

  }
  populateSearchDropdown() {
    this.loading = true;
    if (this.status.length <= 0) {
      this.partyService.getSearchStatus().subscribe(x => {
        // console.log(x);
        this.loading = false;
        this.status = x;
      })
    }
    this.clients$ = this.clientService.clients;

    if (this.states.length <= 0) {
      this.locationService.getStates().subscribe(resp => {
        this.states = resp;
      });
    }

  }
  setFocus(filterbox: MatInput, matExpansionPanel: MatExpansionPanel, event: Event) {
    this.populateSearchDropdown();
    matExpansionPanel.open();
    console.log(matExpansionPanel._getExpandedState());
    //panelH._open();

    setTimeout(() => { // this will make the execution after the above boolean has changed
      filterbox.focus();
      filterbox.focused = true;
    }, 1);
    this.action = "";
    this.selectedParty = null;

  }
  closeSearchPanel(matExpansionPanel: MatExpansionPanel, event: Event) {
    matExpansionPanel.close();
  }
  onSelect(party) {
    this.action = 'view';
    this.selectedParty = party;
  }
  refreshList(forceRefresh: boolean = true) {
    this.resetView();
    this.current_page = 0;
    this.getResults();
  }
  resetView(){
    this.selectedParty = null;
    this.action = '';
  }
}
