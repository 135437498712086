import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OrderModule } from 'ngx-order-pipe';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AttendenceModule } from './attendence/attendence.module';

import { CommondrModule} from './_commondr/commondr.module';
import { SharedModule} from './shared/shared.module';
import { LayoutModule} from './shared/layout/layout.module';
// used to create fake backend
import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ConnectionComponent } from './connection';
import { ExpensesComponent } from './expenses';
import { TennotificationComponent } from './tennotification';
import { PayrollComponent } from './payroll';

import { LeaveComponent } from './leave';
import { MarketreturnsComponent } from './marketreturns';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SetupModule } from './setup/setup.module'
import { HrModule } from './hr/hr.module';
import { ProfileModule } from './profile/profile.module';
import { ClientsComponent } from './clients/clients.component';
import { PartiesModule } from './parties/parties.module';
import { MatCheckboxModule, MatCheckbox } from '@angular/material/checkbox';
import { NmhMaterialModule } from './nmh-material.module';
import { MatTimepickerModule } from 'mat-timepicker';






@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        NgSelectModule,
        InfiniteScrollModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule,
         BrowserModule,
        BrowserAnimationsModule,
        AttendenceModule,
        SharedModule,
        LayoutModule,
        OrderModule,
        CommondrModule,
        SetupModule,
        HrModule,
        ProfileModule,
        PartiesModule,
        NmhMaterialModule,        
        MatTimepickerModule
        ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ConnectionComponent,
        ExpensesComponent,
        TennotificationComponent,
        LeaveComponent,
        PayrollComponent,
        MarketreturnsComponent,
      ClientsComponent
        ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
