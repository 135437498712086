import { Component, OnInit } from '@angular/core';
import { Client } from '../_models/client';
import { ClientService } from '../_services/client.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  clients$: Observable<Client[]>;
  total = 0;
  selectedClient: Client;
  action: string = "";
  loading = false;
  constructor(private clientService: ClientService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.clients$ = this.clientService.clients;
    this.clients$.subscribe(x => {
        this.total = x ? x.length :0;
    })
  }
  pageChanged() {

  }
  pageChangedup() {

  }
  onSelect(client) {
    this.action = "edit";
    this.selectedClient = client;
  }

  onAddClient() {
    this.action = "edit";
    this.selectedClient = new Client();
  }

  onBack() {
    this.action = "";
    this.selectedClient = null;
    this.getAll();
  }
}
