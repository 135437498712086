import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Module } from '../../../_models';
import { Permission } from '../../../_models/permission';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ModuleService } from '../../../_services';
import { Router } from '@angular/router';
import { PermissionService } from '../../../_services/permission.service';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.css']
})
export class AddModuleComponent implements OnInit {

  @Input('module') module: Module = new Module();
  @Output() submitSuccess = new EventEmitter();

  permissions: Array<Permission> = [];
  newAttribute: Permission = new Permission();
  isEditItems: boolean;
  fieldIndex = -1;

  addModuleForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';
  constructor(private moduleService: ModuleService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: Module) {
    this.permissions = this.module.permissions;
    this.populateFields();
    this.submitted = false;
    this.error = '';
    /*for (const propName in changes) {
      const chng = changes[propName];
      let cur:Module = chng.currentValue;
      const prev = JSON.stringify(chng.previousValue);
      
      //this.changeLog.push(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }*/
  }
  onSaveClick() {
    this.submitted = true;
    console.log(this.f.permissions.value);
    // stop here if form is invalid
    if (this.addModuleForm.invalid) {
      return;
    }
    console.log("pass");
    this.loading = true;

    //console.log(this.module.permissions);
    //this.module.name = this.f.modulename.value;
    //this.f.permissions.
    //console.log(this.f.permissions.value);
    //this.module.permissions = this.f.permissions.value;

    let _module: Module = new Module();
    _module.id = this.module.id;
    _module.name = this.f.modulename.value;
    _module.permissions = this.f.permissions.value;
    console.log(this.module);
    this.moduleService.create(_module).subscribe(res => {
      this.module = res;
      this.router.navigate(['/setup/modules']);
      this.loading = false;
      this.submitSuccess.emit();
    },
      error => {
        this.error = error;
        this.loading = false;
      });

  }

  addFieldValue(index) {
    const permissions = this.addModuleForm.get('permissions') as UntypedFormArray
    permissions.push(this.createPermissionFormGroup());

    /* this.fieldIndex = this.fieldIndex - 1;
     this.newAttribute.id = this.fieldIndex;
     this.newAttribute.name = "";
     this.addModuleForm.addControl(this.fieldIndex.toString(), new FormControl('', Validators.required));
     this.permissions.push(this.newAttribute);
     */
    //this.module.permissions.push({ id: this.fieldIndex,name:'' });
    //this.newAttribute = new Permission();
  }
  private createPermissionFormGroup(val = ''): UntypedFormGroup {
    return new UntypedFormGroup({
      'name': new UntypedFormControl(val, Validators.required),
    })
  }
  deleteFieldValue(index) {
    const permissions = this.addModuleForm.get('permissions') as UntypedFormArray
    if (permissions.length > 1) {
      permissions.removeAt(index)
    } else {
      permissions.reset()
    }

    //this.module.permissions.splice(index, 1);
  }

  onEditCloseItems() {
    this.isEditItems = !this.isEditItems;
  }

  populateFields() {
    let parry: any = [];
    if (this.permissions.length > 0) {
      this.permissions.forEach(field => {
        parry.push(this.createPermissionFormGroup(field.name))
      });
    } else {
      //parry.push(this.createPermissionFormGroup(''))
    }
    console.log(parry);
    this.addModuleForm = this.formBuilder.group({
      modulename: [this.module.name, [Validators.required]],
      permissions: this.formBuilder.array(parry)
    });
    /*this.permissions.forEach(field => {
      group[field.name] = new FormControl('', Validators.required)
      
    });
    this.addModuleForm = new FormGroup(group);*/
  }
  // convenience getter for easy access to form fields
  get f() { return this.addModuleForm.controls; }

}
