import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./_services";
import { User } from "./_models";
import { EmployeeService } from "./_services/employee.service";

@Component({ selector: "app", templateUrl: "app.component.html" })
export class AppComponent {
  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
      if (x) {
        this.employeeService.loadDashboardStats();
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

}
